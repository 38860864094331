import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  background: transparent;

  & img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    background: transparent;
  }

  & div {
    background: transparent;
  }

  & div div:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'};
    background: transparent;
  }

  & div div:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.15em;
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' :
    'var(--sub-title-logo-dark)'};
    background: transparent;
  }
`;
