import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Content, ButtonSave, DivButton } from './styles';

import MessageCollector from '../MessageCollector';
import ContainerBotSettings from '../ContainerBotSettings';
import CopyScriptToWebchat from '../CopyScriptToWebchat';
import { toast } from '../../../components/Items/CustomToast';

import { updateSettingsBot } from '../../../store/Boteria/UpdateSettingsBot/UpdateSettingsBot.action';
import { getUser } from '../../../store/UserData/UserData.action';

function CustomBotSettings(props) {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modeColorState = useSelector(state => state.changeModeColor);
  const userData = useSelector(state => state.userData);
  const updateSettingsBotState = useSelector(state => state.updateSettingsBot);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [titleBot, setTitleBot] = useState(null);
  const [mainColor, setMainColor] = useState(null);
  const [secondaryColor, setSecondaryColor] = useState(null);
  const [mainTextColor, setMainTextColor] = useState(null);
  const [secondaryTextColor, setSecondaryTextColor] = useState(null);
  const [sizeIconBot, setSizeIconBot] = useState(null);
  const [imageBot, setImageBot] = useState(null);
  const [settings, setSettings] = useState({});

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  useEffect(() => {
    setSettings({
      titleBot: titleBot === null ? props.settingsBot.settings.headerName : titleBot,
      mainColor: mainColor === null ? props.settingsBot.settings.mainColor : mainColor,
      secondaryColor: secondaryColor === null ? props.settingsBot.settings.secondaryColor : secondaryColor,
      mainTextColor: mainTextColor === null ? props.settingsBot.settings.mainTextColor : mainTextColor,
      secondaryTextColor: secondaryTextColor === null ? props.settingsBot.settings.secondaryTextColor : secondaryTextColor,
      sizeIconBot: sizeIconBot === null ? props.settingsBot.settings.iconSize : sizeIconBot,
      imageBot: imageBot === null ? props.settingsBot.settings.iconWebchat : imageBot,
      setValue: setTitleBot,
      setImageBot: setImageBot,
      setMainColor: setMainColor,
      setSecondaryColor: setSecondaryColor,
      setMainTextColor: setMainTextColor,
      setSecondaryTextColor: setSecondaryTextColor,
      setSizeIconBot: setSizeIconBot
    });
  }, []);

  useEffect(() => {
    dispatch(getUser(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));
  }, [userData.data]);

  useEffect(() => {
    if(!updateSettingsBotState.success && updateSettingsBotState.message !== null){
      toast.error(
        t('toast.errors.oops'),
        t('error_update_settings_bot')
      );
    } else {
      if(updateSettingsBotState.success){
        toast.success(
          t('toast.success.correct'),
          t('toast.success.update_settings_bot')
        );
      }
    }
  }, [updateSettingsBotState]);

  const changeSettingsBot = () => {
    const config = {
      mainColor: mainColor !== null ? mainColor : settings.mainColor,
      secondaryColor: secondaryColor !== null ? secondaryColor : settings.secondaryColor,
      mainTextColor: mainTextColor !== null ? mainTextColor : settings.mainTextColor,
      secondaryTextColor: secondaryTextColor !== null ? secondaryTextColor : settings.secondaryTextColor,
      sizeBotIcon: sizeIconBot !== null ? sizeIconBot : settings.sizeIconBot,
      botIcon: imageBot !== null ? imageBot : settings.imageBot,
      isActive: false,
      botId: window.location.href.split('/')[5],
      token: localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA'),
      headerName: titleBot !== null ? titleBot : settings.titleBot,
      userData
    }
    dispatch(updateSettingsBot(
      config,
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));
  }

  return (
    <Container modeColor={modeColor}>
      <Content modeColor={modeColor}>
        <MessageCollector />
        <ContainerBotSettings settings={settings} />
        <CopyScriptToWebchat settings={settings}/>
      </Content>
      <DivButton modeColor={modeColor}>
        <ButtonSave
          onClick={changeSettingsBot}
        >
          {t('code7_products.boteria.save_custtom_bot_settings_buttom')}
        </ButtonSave>
      </DivButton>
    </Container>
  );
}

export default CustomBotSettings;