import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RiArrowDownSLine } from 'react-icons/ri';

import { ExtractMessages } from '../../../store/Boteria/ExtractMessages/ExtractMessages.action';
import { getReportsAction } from '../../../store/Boteria/GetReports/GetReports.action';
import { getUser } from '../../../store/UserData/UserData.action';

import { toast } from '../../../components/Items/CustomToast';

import { 
  Container,
  Title,
  Description,
  LabelInput,
  InputGroup,
  TimeCourse,
  Button,
  BoxFilterDays,
  Day,
  TitleCsv,
  ListCsv,
  NameCsv,
  ButtomDownload
} from './styles';

function MessageCollector() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modeColorState = useSelector(state => state.changeModeColor);
  const userData = useSelector(state => state.userData);
  const extractMessagesState = useSelector(state => state.extractMessages);
  const getReports = useSelector(state => state.getReports);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [filterDay, setFilterDay] = useState(7);
  const [boxFilterDaysState, setBoxFilterDaysState] = useState(false);
  const [listOfReports, setListOfReports] = useState([]);

  useEffect(() => {
    dispatch(getUser(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));

    if(userData.data !== 'vazio'){
      if(userData.boteria === undefined){
        userData.integrations.forEach(integration => {
          if(integration.name === 'boteria'){
            dispatch(getReportsAction(
              window.location.href.split('/')[5],
              integration.dashboardToken,
              localStorage.getItem('API_TOKEN_PARTNERS')
            ));
          };
        })
      } else {
        dispatch(getReportsAction(
          window.location.href.split('/')[5],
          userData.boteria.dashboardToken,
          localStorage.getItem('API_TOKEN_PARTNERS')
        ));
      }
    }
  }, [userData.data]);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  useEffect(() => {
    if(extractMessagesState._id === '0'){
      toast.error(
        t('toast.errors.oops'),
        t('errors.generate_report')
      );
    } else {
      if(extractMessagesState.message !== 'null'){
        toast.success(
          t('toast.success.correct'),
          t('toast.success.generate_report')
        );
      }
    };
  }, [extractMessagesState]);

  useEffect(() => {
    if(getReports.length > 0){
      if(getReports[0]._id !== 1){
        setListOfReports(getReports);
      };
    }
  }, [getReports[0]]);

  const extractMessages = (day) => {
    if(userData.boteria === undefined){
      userData.integrations.forEach(integration => {
        if(integration.name === 'boteria'){
          dispatch(ExtractMessages(
            integration.dashboardToken,
            window.location.href.split('/')[5],
            day,
            localStorage.getItem('API_TOKEN_PARTNERS')
          ));
          dispatch(getReportsAction(
            window.location.href.split('/')[5],
            integration.dashboardToken,
            localStorage.getItem('API_TOKEN_PARTNERS')
          ));
        };
      })
    } else {
      dispatch(ExtractMessages(
        userData.boteria.dashboardToken, 
        window.location.href.split('/')[5], 
        day,
        localStorage.getItem('API_TOKEN_PARTNERS')
      ));
      dispatch(getReportsAction(
        window.location.href.split('/')[5],
        userData.boteria.dashboardToken,
        localStorage.getItem('API_TOKEN_PARTNERS')
      ));
    }
  }

  return (
    <Container modeColor={modeColor}>
      <Title modeColor={modeColor}>
        {t('code7_products.boteria.message_collector_title')}
      </Title>
      <Description modeColor={modeColor}>
        {t('code7_products.boteria.message_collector_descriptipn')}
      </Description>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.message_collector_label_filter')}
      </LabelInput>
      <InputGroup modeColor={modeColor}>
        <TimeCourse 
          onClick={() => {
            setBoxFilterDaysState(!boxFilterDaysState);
          }}
        >
          {filterDay} {t('code7_products.boteria.message_collector_days')}
        <RiArrowDownSLine />
        </TimeCourse>
        <Button onClick={() => extractMessages(filterDay)}>
          {t('code7_products.boteria.message_collector_button_extract')}
        </Button>
      </InputGroup>
      {boxFilterDaysState ? 
        <BoxFilterDays>
          <Day 
            onClick={() => {
              setBoxFilterDaysState(!boxFilterDaysState)
              setFilterDay(7);
            }}
          >7 {t('code7_products.boteria.message_collector_days')}</Day>
          <Day 
            onClick={() => {
              setBoxFilterDaysState(!boxFilterDaysState)
              setFilterDay(15);
            }}
          >15 {t('code7_products.boteria.message_collector_days')}</Day>
          <Day 
            onClick={() => {
              setBoxFilterDaysState(!boxFilterDaysState)
              setFilterDay(30);
            }}
          >30 {t('code7_products.boteria.message_collector_days')}</Day>
        </BoxFilterDays> 
        : ''
      }
      <TitleCsv modeColor={modeColor}>
        {t('code7_products.boteria.reports_produced')}
      </TitleCsv>
      <Description modeColor={modeColor}>
        {t('code7_products.boteria.descript_reports')}
      </Description>
      {listOfReports.map(report => {
        return (
          <ListCsv key={report._id} modeColor={modeColor}>
            <NameCsv modeColor={modeColor}>
              {report.name}
            </NameCsv>
            <ButtomDownload 
              modeColor={modeColor} 
              href={report.url}
            >
              Download
            </ButtomDownload>
          </ListCsv>
        )
      })}
    </Container>
  );
}

export default MessageCollector;