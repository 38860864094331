import api from '../api';

export const listBotsService = async (tokenBoteria, tokenPartners) => {
	const listBots = api.get('/partner/bot/list', {
    headers: {
      token: 'Bearer' + ' ' + tokenBoteria,
			authorization: 'Bearer' + ' ' + tokenPartners
    }
  }).then(response => {
		if(response.data.result === undefined){
			return {_id: 0, title: 'Nenhum bot encontrado!!', lastUpdate: new Date()}
		} else if(response.data.result.message === 'Request failed with status code 500'){
			return {_id: 0, title: 'Verifique se você possui algum bot criado na boteria', lastUpdate: new Date()}
		}else if(response.data.result.message === 'Request failed with status code 401' || response.data.result === 'Unauthorized'){
			return {_id: 0, title: 'Não foi possíveli listar os seus bots', lastUpdate: new Date()}
		} else{
			return response.data.result;
		};

	}).catch(error => {
		// if(error.response.status === 401){
		// 	window.location.href = '/'
		// };
	});

	return listBots;
}