import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';

import { 
  Container,
  Title, 
  DescriptionBox,
  SubDescriptionBox,
  InputGroup, 
  Label, 
  ErrorMessage,
  LinkGroup, 
  ButtonGroup,
  SelectedGroup,
  SelectDiv,
  DivGroup,
  CompanyBox,
  CompanyOptions
} from './styles';

import Input from '../Input';
import GoogleRecaptcha from '../../GoogleRecaptcha';
import Buttom from '../Button';

function FormBox(props) {

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [lenghtPropButtons, setLengthPropButtons] = useState(1);
  const [boxCompany, setBoxCompany] = useState(false);
  const [valueCompany, setValueCompany] = useState('');
  const [boxSector, setBoxSector] = useState(false);
  const [valueSector, setValueSector] = useState(''); 
  const [arrayCompany, setArrayCompanys] = useState([]);

  useEffect(() => {
    setModeColor(modeColorState);
    setLengthPropButtons(props.buttons.length);
  }, [modeColorState]);

  useEffect(() => {setBoxCompany(false)}, [valueCompany]);

  const arrayPushCompanyValue = [];

  useEffect(() => {
    setArrayCompanys(props.companies);

    if(arrayCompany.length > 0){
      arrayCompany.map(company => {
        if(valueCompany.length > 0 && company.name.includes(valueCompany)){
          arrayPushCompanyValue.push(company);
        }
      });

      setArrayCompanys(arrayPushCompanyValue);
    }

  }, [props.companies, valueCompany]);

  useEffect(() => {setBoxSector(false)}, [arrayCompany]);

  const onChangeValueCompany = (value) => {
    setValueCompany(value);
    props.handleChangeCompany(value);
  }

  return (
    <Container
      modeColor={modeColor}
    >
      <Title modeColor={modeColor}>{props.title}</Title>
      {props.splitBar ? <hr /> : ''}
      {props.description ? 
      <>
        {Array.isArray(props.description) ? 
          props.description.map(descriptionItem => {
            return (
              <DescriptionBox modeColor={modeColor}>
                {descriptionItem}
              </DescriptionBox>  
            )
          }) : 
          <DescriptionBox modeColor={modeColor}>
            {props.description}
          </DescriptionBox>  
        }
      </>
      : ''}
      {props.selectedCompany ? 
      <SelectedGroup modeColor={modeColor}>
        <Label modeColor={modeColor}>Empresa</Label>
        <DivGroup>
          <SelectDiv 
            placeholder='Selecione...'
            value={props.valueCompany ? props.valueCompany : valueCompany}
            onChange={e => {onChangeValueCompany(e.target.value)}}
          />
          <IoIosArrowDown 
            onClick={() => setBoxCompany(boxCompany ? false : true)}
          />
        </DivGroup>
        {boxCompany && 
          <CompanyBox>
            {arrayCompany.map(company => {
              return (
                <CompanyOptions
                  onClick={() => {
                    setValueCompany(company);
                    props.setSelectCompany(company);
                    props.handleChangeCompany(company);
                  }}
                >
                  {company.name}
                </CompanyOptions>
              )
            })}
          </CompanyBox>
        }
        <Label modeColor={modeColor}>Setor</Label>
        <DivGroup>
          <SelectDiv 
            placeholder='Selecione...'
            value={props.valueOrganization}
            onChange={e => props.handleChangeOrganization(e.target.value)}
          />
          <IoIosArrowDown 
            onClick={() => setBoxSector(boxSector ? false : true)}
          />
        </DivGroup>
        {boxSector && 
        <CompanyOptions>
          {props.valueOrganization}
        </CompanyOptions>
        }
      </SelectedGroup> : 
      ''
      }
      <InputGroup>
        {props.inputs.map((inputProp, index) => {
          return (
            <div key={index}>
              <div>
                <Label modeColor={modeColor}>{inputProp.label}</Label>
                <Input 
                  placeholder={inputProp.placeholder}
                  type={inputProp.type}
                  showPassword={inputProp.showPassword}  
                  value={inputProp.value}
                  setValue={inputProp.setValue}
                  errorMessage={inputProp.errorMessage}
                  error={inputProp.error}
                  setErro={inputProp.setErro}
                  id={inputProp.id}
                  disabled={inputProp.disabled}
                />
              </div>
              {inputProp.error ? 
              <>
                <ErrorMessage 
                  modeColor={modeColor}
                >
                  {inputProp.errorMessage}
                </ErrorMessage>
              </> : ''}
            </div>
          )
        })}
      </InputGroup>
      <InputGroup>
        {props.inputErro?.map((inputProp, index) => {
          return (
            <div key={index}>
              <div>
                <Label modeColor={modeColor}>{inputProp.label}</Label>
                <Input 
                  placeholder={inputProp.placeholder}
                  type={inputProp.type}
                  showPassword={inputProp.showPassword}  
                  value={inputProp.value}
                  setValue={inputProp.setValue}
                  errorMessage={inputProp.errorMessage}
                  error={inputProp.error}
                  setErro={inputProp.setErro}
                  id={inputProp.id}
                  disabled={inputProp.disabled}
                />
              </div>
              {inputProp.error ? 
              <>
                <ErrorMessage 
                  modeColor={modeColor}
                >
                  {inputProp.errorMessage}
                </ErrorMessage>
              </> : ''}
            </div>
          )
        })}
      </InputGroup>
      <LinkGroup modeColor={modeColor}>
        {props.links.map((linkProp, index) => {
          return (
            <Link title={linkProp.title} to={linkProp.to} key={index}>{linkProp.text}</Link>
          )
        })}
      </LinkGroup>
      {props.subDescription ? 
      <>
        <SubDescriptionBox>
          {props.subDescription}
        </SubDescriptionBox>
      </>
      : ''}
      {props.setTokenRecaptcha ? 
        <GoogleRecaptcha
          setToken={props.setTokenRecaptcha}
        /> :
        ''
      }
      <ButtonGroup 
        buttonsLenght={lenghtPropButtons}>
        {props.buttons.map((buttonProp, index) => {
          return (
            <Buttom 
              key={index}
              text={buttonProp.text}
              on={buttonProp.on}
              function={buttonProp.function}
              to={buttonProp.to}
              full={buttonProp.full}
              icon={buttonProp.icon}
            />
          )
        })}
      </ButtonGroup>
    </Container>
  );
}

export default FormBox;