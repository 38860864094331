import styled from 'styled-components';

export const Container = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;

  div {
    background: ${
      props => props.modeColor === 'Light Mode' || props.modeColor === null ?
      'var(--box-light)' :
      'var(--box-dark)'
    };
  }
`;

export const BoxProduct = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 310px;
  background: #FFFFFF;
  border: 1px solid #DADCE3;
  border-radius: 4px;
  cursor: ${props => props.active ? 
    'pointer' : 
    'not-allowed'
  };
  margin-right: 15px;
`;

export const HeaderIntegration = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ImageProduct = styled.img`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
`;

export const ButtonActiveBot =  styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  height: 23px;
  background: ${props => props.botActive ? '#E3F8CC !important' : '#f95454 !important'};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.033em;
  font-feature-settings: 'ss01' on;
  color: ${props => props.botActive ? '#19660A' : 'white'};
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    'var(--sub-title-logo-dark)'
  };;
  margin-top: -20px;
  width: 281px;
`;

export const LinkIntegration = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  height: 32px;
  background: #F3F5F9 !important;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #254EDB;
`;