import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

import LogoCode7 from '../../../assets/logoCode7.png';

function Logo() {

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);
  
  return (
    <Container modeColor={modeColor}>
      <img src={LogoCode7} alt="Logo Code7" />
      <div>
        <div>Code7 App</div>
        <div>BETA</div>
      </div>
    </Container>
  );
}

export default Logo;