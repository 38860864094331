import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loader-spinner";

import { Container } from './styles';

import Logo from '../../../components/Items/Logo';
import FormBox from '../../../components/Items/FormBox';
import AlterLanguage from '../../../components/Items/AlterLanguage';
import PoweredBy from '../../../components/Items/PoweredBy';

import { toast } from '../../../components/Items/CustomToast';
import { boteriaLogin } from '../../../store/Auth/BoteriaLogin/BoteriaLogin.action.js';

function BoteriaExistingAccount() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.boteriaLogin);

  const [userConfig, setUserConfig] = useState(localStorage.getItem('USER_CONFIG'));
  const [password, setPassword] = useState();
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState();
  const [tokenRecaptcha, setTokenRecaptcha] = useState(0);
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setUserConfig(JSON.parse(userConfig));
    setEmail(JSON.parse(userConfig).email);
  }, []);

  useEffect(() => {
    const verifyMessage = userData.data === undefined ? userData.message : userData.data.message;

    if(userData.status !== 0 && verifyMessage !== 'success'){
      if(userData.user.selectedOrg || userData.user.claimIsRoot){
        localStorage.setItem('API_TOKEN_PARTNERS_BOTERIA', userData.user.tokenBoteria);
        localStorage.setItem('PAGE_ORIGIN', 'Login Boteria');
        localStorage.setItem('DADOS_USER_BOTERIA', JSON.stringify(userData));

        setTimeout(() => {
          window.location.href = `/selected-organization`;          
        }, 2000);
      } else {
        setLoader(false);
        toast.error(
          t('toast.errors.oops'),
          t(`errors.${userData.message ? userData.message : userData}`)
        );
      }
    } else {
      if(
        userData.status === 200 && 
        userData.data.tokenBoteria !== undefined
      )
      {
        localStorage.removeItem('USER_CONFIG')
        localStorage.setItem('API_TOKEN_PARTNERS', userData.data.token);
        localStorage.setItem('API_TOKEN_PARTNERS_BOTERIA', userData.data.tokenBoteria);
        localStorage.setItem('EMAIL', userData.data.response.email);

        setTimeout(() => {
            window.location.href = 
            `/${userData.data.response.originInitial !== undefined ? userData.data.response.originInitial : 'nuvemshop'}/home`;          
        }, 5000);
      }
    }
  }, [userData.status]);

  const SigninBoteriaAndCreateAccountPartners = () => {

    let errorPassword = false;

    if(!errorPassword && !password){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.password_empty'));
      setErrorPassword(true);
    } 
    else if(tokenRecaptcha === 0) {
      toast.error(
        t('toast.errors.oops'),
        t('toast.errors.google_recaptcha_empty')
      );
    }
     else {
      const user = {
        name: userConfig.name,
        email: userConfig.email,
        phone: userConfig.phone,
        companyName: userConfig.companyName,
        password: password,
        userIdStore: userConfig.userIdStore,
        accessToken: userConfig.accessToken,
        refreshToken: userConfig.refreshToken,
        origin: userConfig.origin,
        tokenReCaptcha: tokenRecaptcha,
        code: userConfig.code
      }

      setLoader(true);
      dispatch(boteriaLogin(user));
    }
  }

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Logo />

      <FormBox 
        title={t('boteroia_existing_account.title')}
        splitBar={true}
        inputs={[
          {
            label: t('labels.email'), 
            type: 'e-mail', 
            id: 'idEmailBoteriaExistingAccount',
            placeholder: t('placeholders.email_example'),
            value: email,
            disabled: true
          },
          {
            label: t('labels.password'), 
            type: 'password',
            id: 'idPasswordBoteriaExistingAccount',
            showPassword: false,
            value: password,
            setValue: setPassword,
            error: errorPassword,
            errorMessage: errorPasswordMessage,
            setErro: setErrorPassword
          }
        ]}
        links={[]}
        setTokenRecaptcha={setTokenRecaptcha}
        buttons={[
          {
            text: t('buttons.confirm'),
            on: false,
            function: SigninBoteriaAndCreateAccountPartners,
          }
        ]}
      />

      <AlterLanguage />

      <PoweredBy />
    </Container>
  );
}

export default BoteriaExistingAccount;