import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container } from './styles.js';

import { getUser } from '../../../store/UserData/UserData.action';

import Menu from '../../../components/Items/Menu';
import ContainerPage from '../../../components/Items/ContainerPage';
import ListOfBots from '../../../components/Boteria/ListOfBots';

function Boteria() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.userData);

  useEffect(() => {
    dispatch(getUser(localStorage.getItem('EMAIL'), localStorage.getItem('API_TOKEN_PARTNERS')));
    if(userData.prefererLanguage){
      if(userData.prefererLanguage === 'English'){
        i18n.changeLanguage('en');
      }

      if(userData.prefererLanguage === 'Português (Brasil)'){
        i18n.changeLanguage('pt-br');
      }
    }
  }, [userData.data]);

  return (
    <Container>
      <Menu/>
      <ContainerPage
        title={t('code7_products.boteria.title')}
        subtitle={t('code7_products.boteria.subtitle')}
        hr={true}
        description={t('code7_products.boteria.description')}
      >
        <ListOfBots />
      </ContainerPage>
    </Container>
  )
}

export default Boteria;