import styled from 'styled-components';

export const Container = styled.div`
  width: 256px;
  padding: 24px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--background-menu-light)' :
    'var(--background-menu-dark)'
  };
`;

export const MenuElement = styled.div`
  margin: 8px 0;
  margin-left: ${props => props.submenu ? '10px' : ''};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  width: ${props => props.submenu ? '199px' : '208px'};
  height: 42px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.activeMenu && props.modeColor === 'Light Mode' || 
    props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    props.activeMenu && props.modeColor === 'Dark Mode' ? 
    'blue' : 
    !props.activeMenu && props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    !props.activeMenu && props.modeColor === 'Dark Mode' || props.modeColor === null ? 
    'white' : 
    ''
  };
  cursor: ${props => props.status ? 
    'pointer' : 
    'not-allowed'
  };
  background: ${props => props.activeMenu ? 
    '#FFFFFF' : 
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--background-menu-light)' :
    'var(--background-menu-dark)'
  };

  div {
    margin-top: 4px;
    background: ${props => props.activeMenu ? 
      '#FFFFFF' : 
      props => props.modeColor === 'Light Mode' || props.modeColor === null ?
      'var(--background-menu-light)' :
      'var(--background-menu-dark)'
    };
  }

  svg {
    margin-right: 8px;
    width: 25px;
    height: 25px;
    color: ${props => props.activeMenu && props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--sub-title-logo-light)' : 
      props.activeMenu && props.modeColor === 'Dark Mode' ? 
      'blue' : 
      !props.activeMenu && props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--sub-title-logo-light)' : 
      !props.activeMenu && props.modeColor === 'Dark Mode' ? 
      'white' : 
      ''
    };
    background: ${props => props.activeMenu ? 
      '#FFFFFF' : 
      props => props.modeColor === 'Light Mode' || props.modeColor === null ?
      'var(--background-menu-light)' :
      'var(--background-menu-dark)'
    };
  }

  &:hover {
    background: ${props => props.status ? '#FFFFFF' : ''};
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--sub-links-light)' : 
      'blue'
    };

    div {
      background: ${props => props.status ? '#FFFFFF' : ''};
    }

    svg {
      background: ${props => props.status ? '#FFFFFF' : ''};
      color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
        'var(--sub-links-light)' : 
        'blue'
      };
    }
  }
`;

export const SplitBarMenu = styled.div`
  width: 208px;
  height: 0px;
  border: 1px solid #DADCE3;
  margin: 19px 0;
`;

export const PhotoProfile = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 8.5px;
  object-fit: cover;
`;
