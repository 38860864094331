import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 17px;
  width: 435px;
  height: 635px;
  left: 493px;
  top: 0px;
  background: #F3F5F9;
  border: 1px solid #DADCE3;
  border-radius: 4px;
  padding: 50px 55px 0;

  img {
    margin-top: 12px;
    margin-left: 250px;
    background: transparent;
  }
`;

export const HeaderChatbot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 324px;
  height: 48px;
  background: ${props => props.backgroundColor};
  border-radius: 16px 16px 0px 0px;
`;

export const TitleBot = styled.div`
  background: ${props => props.backgroundColor};
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.color};
`;

export const GroupIconlist = styled.div`
  display: flex;
  background: ${props => props.backgroundColor};

  svg {
    margin: 0 0 0 16px;
    font-weight: 900;
    font-size: 16px;
    line-height: 143%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.018em;
    color: #FFFFFF;
    background: ${props => props.backgroundColor};
    width: 20px;
    height: 20px;
  }
`;

export const ContentMainChatbot = styled.div`
  width: 324px;
  height: 338px;
  background: #FFFFFF;
  padding: 21px 16px;
  display: flex;
  justify-content: space-between;
`;

export const BotMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 122px;
  height: 40px;
  background: ${props => props.backgroundColor};
  border-radius: 16px 16px 16px 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.color};
`;

export const ClientMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 4px;
  width: 51px;
  height: 40px;
  background: ${props => props.backgroundColor};
  border-radius: 16px 16px 4px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  text-align: right;
  color: ${props => props.color};
  margin-top: 50px;
  text-align: end;
`;

export const SendMessageBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  width: 324px;
  height: 56px;
  background: #EAEDEF;
  border-radius: 0px;
`;

export const ClipsIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 32px;
  height: 32px;
  background: #F3F5F9;
  border-radius: 4px;

  svg {
    width: 32px;
    height: 32px;
    background: transparent;
  }
`;

export const InputGroupChatbot = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 275px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  margin-left: 4px;

  svg {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    cursor: pointer;
  }
`;

export const InputChatbot = styled.input`
  width: 218px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #979AA5;
  background: #FFFFFF;
`;

export const FooterChatbot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 324px;
  height: 28px;
  background: #A8B1BF;
  border-radius: 0px 0px 16px 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
`;