import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12), 0px 8px 24px rgba(24, 39, 75, 0.08);

  hr {
    border: 1px solid #DADCE3;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };

  img {
    width: 64px;
    height: 64px;
    margin-left: 24px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-content-page-light)' : 
    'var(--title-content-page-dark)'
  };
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 24px 0 0 24px;
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    'var(--sub-title-logo-dark)'
  };
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 0 0 24px 24px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  position: absolute;
  width: 976px;
  height: 65px;
  left: 280px;
  top: 115px;
  background: #F3F5F9;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #5A5D68;
`;