import styled from 'styled-components';

export const Container = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
`;

export const DivButton = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  justify-content: flex-end;
`;

export const ButtonSave = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 88px;
  height: 40px;
  background: #254EDB;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-top: 32px;
  cursor: pointer;
`;
