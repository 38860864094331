import styled from 'styled-components';

export const Container = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  border: 1px solid #DADCE3;
  border-radius: 4px;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
`;

export const Title = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const Description = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    'var(--sub-title-logo-dark)'
  };
  margin-top: -20px;
`;

export const LabelInput = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.02em;
  font-feature-settings: 'ss01' on;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const InputGroup = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
`;

export const TimeCourse = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
  width: 197px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #5A5D68;

  svg {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 89px;
  height: 40px;
  background: #254EDB;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
`;

export const BoxFilterDays = styled.div`
  border: 1px solid #DADCE3;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  margin-top: -10px;
  z-index: 10;
`;

export const Day = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 197px;
  height: 36px;
  background: #FFFFFF;
  cursor: pointer;

  &:hover {
    background: #D6E4FF;
  }
`;

export const TitleCsv = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--box-light)' :
    'var(--box-dark)'
  };
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  margin-right: 8px;
`;

export const ListCsv = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const NameCsv = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  margin-right: 8px;
`;

export const ButtomDownload = styled.a`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 89px;
  height: 40px;
  background: #254EDB;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none;
`;