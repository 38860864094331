import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

import Logo from '../../../components/Items/Logo';
import FormBox from '../../../components/Items/FormBox';
import AlterLanguage from '../../../components/Items/AlterLanguage';
import PoweredBy from '../../../components/Items/PoweredBy';

function AccountCreate() {
  const { t } = useTranslation();

  return (
    <Container>
      <Logo />

      <FormBox 
        title={t('account_created.title')}
        splitBar={true}
        description={[t('account_created.description_text_up'), t('account_created.description_text_down')]}
        inputs={[]}
        links={[]}
        buttons={[
          {
            text: t('buttons.return_to_login'),
            on: false,
            to: '/',
            full: true,
            icon: true
          }
        ]}
      />

      <AlterLanguage />

      <PoweredBy />
    </Container>
  )
}

export default AccountCreate;