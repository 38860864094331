import api from '../api';

export const deleteBotService = async (email, tokenPartners) => {
	const deleteBot = await api.post('/partner/bot/delete', {
		email: email
	}, {
		headers: {
			authorization: 'Bearer' + ' ' + tokenPartners
		}
	}).then((resultDelete) => {
		return resultDelete.data;
	}).catch(() => {
		return {
			id: 0
		}
	});

	return deleteBot;
}