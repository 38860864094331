import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loader-spinner";

import { Container } from './styles';

import Logo from '../../../components/Items/Logo';
import FormBox from '../../../components/Items/FormBox';
import AlterLanguage from '../../../components/Items/AlterLanguage';
import PoweredBy from '../../../components/Items/PoweredBy';

import { toast } from '../../../components/Items/CustomToast';
import { create } from '../../../store/Auth/CreateUser/CreateUser.actions';

function Login() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.user);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState();
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState();
  const [tokenRecaptcha, setTokenRecaptcha] = useState(0);
  const [companyName, setCompanyName] = useState();
  const [errorCompanyName, setErrorCompanyName] = useState(false);
  const [errorCompanyNameMessage, setErrorCompanyNameMessage] = useState();
  const [name, setName] = useState();
  const [errorName, setErrorName] = useState(false);
  const [errorNameMessage, setErrorNameMessage] = useState();
  const [phone, setPhone] = useState();
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPhoneMessage, setErrorPhoneMessage] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] = useState();
  const [loader, setLoader] = useState(false);

  let url = window.location.href;

  const newUrl = url.split('/');

  useEffect(() => {
    if(userData.status !== 0 && userData.status !== 200){
      setLoader(false);
      toast.error(
        t('toast.errors.oops'),
        t(`errors.${userData.message ? userData.message : userData}`)
      );

      if(userData.error === 'Já existe um usuário com esse email cadastrado.'){
        setTimeout(() => {
          window.location.href = '/boteria-existing-account';
        }, 2000);
      }
    } else {
      if(userData.status === 200) {
        window.location.href ='/account-created';
      }
    }
  }, [userData]);

  const createAccount = () => {
    let errorEmail = false;
    let errorPassword = false;
    let errorCompanyName = false;
    let errorName = false;
    let errorPhone = false;
    let errorConfirmPassword = false;

    if(!errorEmail && !email){
      errorEmail = true;
      setErrorEmailMessage(t('errors.email_empty'));
      setErrorEmail(true);
    }

    if(!errorEmail && !email.includes('@') || !errorEmail && !email.includes('.')){
      setErrorEmailMessage(t('errors.email_incorrect'));
      setErrorEmail(true);
    }

    if(!errorPassword && !password){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.password_empty'));
      setErrorPassword(true);
    }

    if(!errorPassword && password.lenght < 6){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.small_password'));
      setErrorPassword(true);
    }

    if(!errorPassword && password.match(/[a-z]/) === null){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.general_password_error'));
      setErrorPassword(true);
    }

    if(!errorPassword && password.match(/[A-Z]/) === null){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.general_password_error'));
      setErrorPassword(true);
    }

    if(!errorPassword && password.match(/[0-9]/) === null){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.general_password_error'));
      setErrorPassword(true);
    }

    if(!errorCompanyName && !companyName) {
      errorCompanyName = true;
      setErrorCompanyNameMessage(t('errors.company_name_empty'));
      setErrorCompanyName(true);
    }

    if(!errorName && !name) {
      errorName = true;
      setErrorNameMessage(t('errors.name_empty'));
      setErrorName(true);
    }

    if(!errorPhone && !phone || !errorPhone && phone.lenght < 14) {
      errorPhone = true;
      setErrorPhoneMessage(t('errors.phone_number_empty'));
      setErrorPhone(true);
    }

    if(!errorConfirmPassword && !confirmPassword) {
      errorConfirmPassword = true;
      setErrorConfirmPasswordMessage(t('errors.confirm_password_empty'));
      setErrorConfirmPassword(true);
    }

    if(password !== confirmPassword) {
      errorConfirmPassword = true;
      setErrorConfirmPasswordMessage(t('errors.confirm_password_different'));
      setErrorConfirmPassword(true);
    } 
    else if(tokenRecaptcha === 0) {
      toast.error(
        t('toast.errors.oops'),
        t('toast.errors.google_recaptcha_empty')
      );
    }
     else {
      const user = {
        name: name,
        email: email,
        phone: phone,
        companyName: companyName,
        password: password,
        accessToken: newUrl[6],
        tokenReCaptcha: tokenRecaptcha,
        origin: newUrl[4],
        code: newUrl[7],
        resource: 'partner'
      }

      newUrl[4] === 'rd' ? user.refreshToken = newUrl[5] : user.userIdStore = newUrl[5]
      localStorage.setItem('USER_CONFIG', JSON.stringify(user));

      setLoader(true);
      dispatch(create(user));
    }
  }

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Logo />

      <FormBox 
        title={t('signup.create_account')}
        splitBar={true}
        inputs={[
          {
            label: `${t('labels.company_name')}*`, 
            type: 'text', 
            id: 'idCompanyName',
            placeholder: '',
            value: companyName,
            setValue: setCompanyName,
            error: errorCompanyName,
            errorMessage: errorCompanyNameMessage,
            setErro: setErrorCompanyName,
          },
          {
            label: `${t('labels.name')}*`, 
            type: 'text', 
            id: 'idName',
            placeholder: '',
            value: name,
            setValue: setName,
            error: errorName,
            errorMessage: errorNameMessage,
            setErro: setErrorName,
          },
          {
            label: `${t('labels.phone_number')}*`, 
            type: 'phone', 
            id: 'idPhoneNumber',
            placeholder: t('placeholders.phone_number'),
            value: phone,
            setValue: setPhone,
            error: errorPhone,
            errorMessage: errorPhoneMessage,
            setErro: setErrorPhone,
          },
          {
            label: `${t('labels.email')}*`, 
            type: 'e-mail', 
            id: 'idEmailCreate',
            placeholder: t('placeholders.email_example'),
            value: email,
            setValue: setEmail,
            error: errorEmail,
            errorMessage: errorEmailMessage,
            setErro: setErrorEmail,
          },
          {
            label: `${t('labels.password')}*`, 
            type: 'password',
            id: 'idPasswordCreate',
            showPassword: false,
            value: password,
            setValue: setPassword,
            error: errorPassword,
            errorMessage: errorPasswordMessage,
            setErro: setErrorPassword
          },
          {
            label: `${t('labels.confirm_password')}*`, 
            type: 'password',
            id: 'idConfirmPassoword',
            showPassword: false,
            value: confirmPassword,
            setValue: setConfirmPassword,
            error: errorConfirmPassword,
            errorMessage: errorConfirmPasswordMessage,
            setErro: setErrorConfirmPassword
          }
        ]}
        links={[]}
        subDescription={t('description_create_account')}
        setTokenRecaptcha={setTokenRecaptcha}
        buttons={[
          {
            text: t('buttons.toBack'),
            on: true,
            function: '',
            to: newUrl[4] === undefined ? '/' :
            newUrl[4] === 'rd' ? 
            `/signin/rd/${newUrl[5]}/${newUrl[6]}/${newUrl[7]}` :
            `/signin/nuvemshop/${newUrl[5]}/${newUrl[6]}`
          },
          {
            text: t('buttons.create_account'),
            on: false,
            function: createAccount,
          }
        ]}
      />

      <AlterLanguage />

      <PoweredBy />
    </Container>
  );
}

export default Login;