import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Container, Title, Subtitle, Description, HeaderContainer } from './styles';

function ContainerPage(props) {

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  return (
    <Container modeColor={modeColor}>
      <HeaderContainer modeColor={modeColor}>
        {props.img ? 
          <img src={props.img} alt="Imagem da página" /> : 
          ''
        }
        <div>
          <Title modeColor={modeColor}>{props.title}</Title>
          <Subtitle modeColor={modeColor}>{props.subtitle}</Subtitle>
        </div>
      </HeaderContainer>
      {props.hr ? 
        <hr /> : 
        ''
      }
      {props.description ? 
      <Description>
        {props.description}
      </Description> : ''}
      {props.children}
    </Container>
  );
}

export default ContainerPage;