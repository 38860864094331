import api from '../api';

export const createUser = async (user) => {
	const createAccount = await api.post('partner/user/create', {
		user
	}).then(result => {
		if(result.data.status === undefined){
			return 'unexpected_error';
		} else {
			return result.data;
		}
	})
	.catch(err => {
		return err.response.data;
	});

	return createAccount;
}