import api from './api';

export const userDataService = async (email, tokenPartners) => {
	const getUser = await api.get('partner/user', { headers: {
		email: email,
		authorization: 'Bearer' + ' ' + tokenPartners
	}}).then(result => {
		return result.data;
	})
	.catch(err => {
		if(err.response.status === 401){
			window.location.href = '/'
		};
		return 'error';
	});

	return getUser;
}