import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from "react-loader-spinner";

import { Container } from './styles';

import Menu from '../../components/Items/Menu';
import ContainerPage from '../../components/Items/ContainerPage';
import ListCode7AppIntegrations from '../../components/ListCode7AppIntegrations';

import { getUser } from '../../store/UserData/UserData.action';
import { getScriptNuvemshopAction } from '../../store/Nuvemshop/GetScriptNuvemshop/GetScript.action';

function IntegrationsPage() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.userData);
  const getScript = useSelector(state => state.getScript);

  const [userDataState, setUserDataState] = useState(false);
  const [integrationNuvemshopActive, setIntegrationNuvemshopActive] = useState(false);
  const [integrationRDActive, setIntegrationRDActive] = useState(false);
  const [loader, setLoader] = useState(false);

  const arrayAccessToken = [];
  const arrayUserIdStore = [];

  useEffect(() => {
    setLoader(true);
    dispatch(getUser(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));
    if(userData?.data !== 'vazio' || userData.integration !== undefined){
      setUserDataState(userData.data);
      setLoader(false);
      if(userData.integrations){
        userData.integrations.map(integration => {
          if(integration.name === 'nuvemshop'){
            arrayAccessToken.push(integration.accessToken);
            arrayUserIdStore.push(integration.userId);
          }
          if(integration.name === 'rd'){
            setIntegrationRDActive(true);
          }
        });

        if(userData.prefererLanguage){
          if(userData.prefererLanguage === 'English'){
            i18n.changeLanguage('en');
          }
  
          if(userData.prefererLanguage === 'Português (Brasil)'){
            i18n.changeLanguage('pt-br');
          }
        }

        userData.integrations.map(integration => {
          if(integration.name === 'nuvemshop'){
            dispatch(getScriptNuvemshopAction(
              integration.userIdStore, 
              arrayAccessToken[0],
              localStorage.getItem('API_TOKEN_PARTNERS')  
            ));
          }
        })
      } else {
        dispatch(getScriptNuvemshopAction(
          userData.data.userIdSore,
          userData.data.accessToken,
          localStorage.getItem('API_TOKEN_PARTNERS')
        ));
      }
    }
  }, [userData?.data]);

  useEffect(() => {
    if(getScript > 1){
      setIntegrationNuvemshopActive(true);
    }
  }, [getScript.message]);

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Menu/>
      <ContainerPage
        title={t('code7_products.integrations.title')}
        subtitle={t('code7_products.integrations.description')}
        hr={true}
      >
        <ListCode7AppIntegrations 
          userData={userDataState}
          integrationNuvemshopActive={integrationNuvemshopActive}
          integrationRDActive={integrationRDActive}
        />
      </ContainerPage>
    </Container>
  );
}

export default IntegrationsPage;