import styled from 'styled-components';

export const Container = styled.div`
  background: var(--box);
  display: flex;

  > div {
    margin: 16px auto;
  }

  > div > div > div > div{
    background: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--box-light)' : 
    'var(--box-dark)'};
  }

  iframe {
    border: 1px solid ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--box-light)' : 
    'var(--box-dark)'};
  }
`;
