import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from "react-loader-spinner";

import { RiArrowDownSLine } from 'react-icons/ri';

import { 
  Container,
  Title,
  PhotoEditor,
  ButtomEditPhoto,
  LabelInput,
  InputEditor,
  TitleLanguage,
  BoxLanguage,
  BoxSetLanguage,
  Language,
  ButtomSaveUpdates
} from './styles';

import { getUser } from '../../store/UserData/UserData.action';
import { updateUserAction } from '../../store/UpdateUser/UpdateUser.action';

import Menu from '../../components/Items/Menu';
import ContainerPage from '../../components/Items/ContainerPage';
import { toast } from '../../components/Items/CustomToast';

import profileImage from './profileImage.js';

function Profile() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const modeColorState = useSelector(state => state.changeModeColor);
  const userData = useSelector(state => state.userData);
  const updateUser = useSelector(state => state.updateUser);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [boxSetLanguageState, setBoxSetLanguageState] = useState(false);
  const [imageProfileUser, setImageProfileUser] = useState(profileImage.imageUser);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [language, setLanguage] = useState('Português');
  const [updatePassword, setUpdatePassword] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [typeInput, setTypeInput] = useState('password');
  const [loader, setLoader] = useState(false);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  useEffect(() => {
    if(updateUser.success){
      toast.success(
        t('toast.success.correct'),
        t('toast.success.update_user')
      );
    } else {
      if(updateUser.success === undefined){
        setLoader(false);
        toast.error(
          t('toast.errors.oops'),
          t('errors.into_error')
        );
      }
    }
  }, [updateUser.success]);

  useEffect(() => {
    setLoader(true);
    dispatch(getUser(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));

    if(userData.data !== 'vazio'){
      setLoader(false);
      setUserName(userData.name);
      setUserEmail(userData.email);

      if(userData.prefererLanguage){
        setLanguage(userData.prefererLanguage)

        if(userData.prefererLanguage === 'English'){
          i18n.changeLanguage('en');
        }

        if(userData.prefererLanguage === 'Português (Brasil)'){
          i18n.changeLanguage('pt-br');
        }
      }

      if(userData.profileImage) {
        setImageProfileUser(userData.profileImage);
      }
    }
  }, [userData.data]);

  const handleChangeLanguage = (newLanguage, value) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(value);
    setBoxSetLanguageState(false);
  }

  const item = {};

  const onChangeProfilei = (files, sizeBot) => {
    if (files && files.length > 0) {
      const file = files[0]
      if (!file.type) {
        toast.error(
          t('toast.errors.oops'),
          t('errors.type_image_undefined')
        );
      } else if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
        toast.error(
          t('toast.errors.oops'),
          t('type_image_incorrect')
        );
      } else {
        const reader = new FileReader()
        reader.onload = (e) => {
          const image = new Image()
          image.src = e.target['result']
          image.onload = () => {
            if(image.width > Number(sizeBot) || image.height > Number(sizeBot)){
              toast.error(
                t('toast.errors.oops'),
                t('errors.size_image')
              );
            } else {
              setImageProfileUser(e.target['result']);
              toast.success(
                t('toast.success.correct'),
                t('toast.success.image_profile')
              );
            }
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Menu imageProfileUser={imageProfileUser} userName={userName}/>
      <ContainerPage
        title={t('code7_products.profile.title')}
        hr={true}
        description={t('code7_products.profile.warning')}
      >
        <Title modeColor={modeColor}>
          {t('code7_products.profile.identify')}
        </Title>
        <PhotoEditor modeColor={modeColor}>
          <img src={imageProfileUser} alt="Foto do perfil" />
          <ButtomEditPhoto onClick={() => item.botFabInputFile.click()}>
            {t('code7_products.profile.edit_photo')}
          </ButtomEditPhoto>
          <input style={{display: 'none'}} type="file" accept="image/png" onChange={e => onChangeProfilei(e.target.files, 245)} ref={input => item.botFabInputFile = input}/>
        </PhotoEditor>
        <LabelInput modeColor={modeColor}>
          {t('code7_products.profile.name_label')}
        </LabelInput>
        <InputEditor 
          value={userName}
          onChange={e => setUserName(e.target.value)}
        />
        <LabelInput modeColor={modeColor}>
          {t('code7_products.profile.email_label')}
        </LabelInput>
        <InputEditor 
          modeColor={modeColor}
          value={userEmail}
          disabled={true}
        />
        {updatePassword ? 
          <>
            <LabelInput modeColor={modeColor}>
            {t('code7_products.profile.new_password_label')}
            </LabelInput>
            <InputEditor 
              type={typeInput}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <LabelInput modeColor={modeColor}>
            {t('code7_products.profile.confirm_new_password_label')}
            </LabelInput>
            <InputEditor 
              type={typeInput}
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
            />
            <TitleLanguage style={{fontSize: '12px'}} modeColor={modeColor}>
              {t('description_create_account')}
            </TitleLanguage>
            <ButtomSaveUpdates
              margin={true}
              onClick={() => {
                if(typeInput === 'password'){
                  setTypeInput('text');
                } else {
                  setTypeInput('password');
                }
              }}
            >
              {typeInput === 'password' ? 
                t('code7_products.profile.show_password')
                : 
                t('code7_products.profile.hide_password')
              }
            </ButtomSaveUpdates>
          </>
          : 
          ''
        }
        {!updatePassword ? 
          <ButtomSaveUpdates
            margin={true}
            onClick={() => {
              setUpdatePassword(true);
            }}
          >
            {t('code7_products.profile.update_password')}
          </ButtomSaveUpdates>
          : 
          ''
        }
        <TitleLanguage modeColor={modeColor}>
          {t('code7_products.profile.interface_language')}
        </TitleLanguage>
        <BoxLanguage 
          onClick={() => {
            setBoxSetLanguageState(!boxSetLanguageState);
          }}
        >
          {language}
          <RiArrowDownSLine />
        </BoxLanguage>
        {boxSetLanguageState ? 
          <BoxSetLanguage modeColor={modeColor}>
            <Language 
              onClick={() => {
                setBoxSetLanguageState(!boxSetLanguageState);
                handleChangeLanguage('Português (Brasil)', 'pt-br');
              }}
            >Português (Brasil)</Language>
            <Language 
              onClick={() => {
                setBoxSetLanguageState(!boxSetLanguageState);
                handleChangeLanguage('English', 'en');
              }}
            >English</Language>
          </BoxSetLanguage> 
          : ''
        }
        <ButtomSaveUpdates
          onClick={() => {
            if(updatePassword){
              if(newPassword === undefined || confirmNewPassword === undefined) {
                toast.error(
                  t('toast.errors.oops'),
                  t('errors.password_empty')
                );
              } else if(newPassword !== confirmNewPassword) {
                toast.error(
                  t('toast.errors.oops'),
                  t('errors.password_are_not_the_same')
                );
              }else {
                dispatch(updateUserAction(
              {imageProfileUser, userName, language, userEmail, newPassword},
              localStorage.getItem('API_TOKEN_PARTNERS')
              ))
              }
            } else {
              dispatch(updateUserAction(
                {imageProfileUser, userName, language, userEmail, newPassword},
                localStorage.getItem('API_TOKEN_PARTNERS')
              ))
            }
          }}
        >
          {t('code7_products.profile.save_buttom')}
        </ButtomSaveUpdates>
        <ButtomSaveUpdates
          onClick={() => window.location.href = '/'}
        >
          {t('code7_products.profile.logout')}
        </ButtomSaveUpdates>
      </ContainerPage>
    </Container>
  );
}

export default Profile;