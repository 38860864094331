import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loader-spinner";

import { Container } from './styles';

import Logo from '../../../components/Items/Logo';
import FormBox from '../../../components/Items/FormBox';
import AlterLanguage from '../../../components/Items/AlterLanguage';
import PoweredBy from '../../../components/Items/PoweredBy';

import { toast } from '../../../components/Items/CustomToast';
import { LoginAction } from '../../../store/Auth/Login/Login.action';

function Login() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.login);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState();
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState();
  const [tokenRecaptcha, setTokenRecaptcha] = useState(0);
  const [passwordBoteria, setPasswordBoteria] = useState();
  const [errrorPasswordBoteria, setErrorPasswordBoteria] = useState(false);
  const [loader, setLoader] = useState(false);

  const url = window.location.href.split('/');

  useEffect(() => {
    if(userData.message !== undefined && userData.message !== 'success') {
      if(userData.response?.integrations !== undefined) {
        if(localStorage.getItem('USER_NUVEMSHOP') === null){
          userData.response.integrations.map(integration => {
            if(integration.name === 'nuvemshop'){
              localStorage.setItem('USER_NUVEMSHOP', integration.userIdStore);
            }
          })
        }
      }
      
      if(userData.selectedOrg || userData.claimIsRoot){
        localStorage.setItem('API_TOKEN_PARTNERS_BOTERIA', userData.tokenBoteria);
        localStorage.setItem('PAGE_ORIGIN', 'Login');
        localStorage.setItem('DADOS_USER_BOTERIA', JSON.stringify(userData));

        setTimeout(() => {
          window.location.href = `/selected-organization`;          
        }, 2000);
      } else {
        if(userData.message === 'invalid_password_boteria') {
          setErrorPasswordBoteria(true);
        }

        setLoader(false);
        toast.error(
          t('toast.errors.oops'),
          t(`errors.${userData.message}`)
        );
      }
    } 

    if(userData.status === 200 && userData.message !== 'Select Org') {
      if(userData.response.integrations !== undefined) {
        if(localStorage.getItem('USER_NUVEMSHOP') === null){
          userData.response.integrations.map(integration => {
            if(integration.name === 'nuvemshop'){
              localStorage.setItem('USER_NUVEMSHOP', integration.userIdStore);
            }
          })
        }
      }
      localStorage.setItem('API_TOKEN_PARTNERS', userData.token);
      localStorage.setItem('API_TOKEN_PARTNERS_BOTERIA', userData.tokenBoteria);
      localStorage.setItem('EMAIL', userData.response.email);

      setTimeout(() => {
          window.location.href = 
          `/${userData.response.originInitial !== undefined ? userData.response.originInitial : 'nuvemshop'}/home`;          
      }, 5000);
    } else if(userData.status !== 0 && userData.message !== 'Select Org') {
      setLoader(false);
    }
  }, [userData]);

  const Signin = () => {
    let errorEmail = false;
    let errorPassword = false;

    if(!errorEmail && !email){
      errorEmail = true;
      setErrorEmailMessage(t('errors.email_empty'));
      setErrorEmail(true);
    }

    if(!errorEmail && !email.includes('@') || !errorEmail && !email.includes('.')){
      setErrorEmailMessage(t('errors.email_incorrect'));
      setErrorEmail(true);
    }

    if(!errorPassword && !password){
      errorPassword = true;
      setErrorPasswordMessage(t('errors.password_empty'));
      setErrorPassword(true);
    } 
    else if(tokenRecaptcha === 0) {
      toast.error(
        t('toast.errors.oops'),
        t('toast.errors.google_recaptcha_empty')
      );
    } 
    else {

      const tokenBoteriaStorage = localStorage.getItem('API_TOKEN');

      const user = {
        email: email,
        password: password,
        tokenRecaptcha: tokenRecaptcha,
        origin: url[4] !== undefined ? url[4] : '',
        accessToken: url[6] !== undefined ? url[6] : '',
        code: url[7] !== undefined ? url[7] : '',
        tokenBoteriaStorage: tokenBoteriaStorage,
        passwordBoteria: passwordBoteria,
        errrorPasswordBoteria: errrorPasswordBoteria
      }

      url[4] === 'rd' ? 
      user.refreshToken_rd = url[6] : 
      user.userIdStore = url[5]

      localStorage.setItem('USER_CONFIG', JSON.stringify(user));

      setLoader(true);
      dispatch(LoginAction(user));
    }
  }

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Logo />

      <FormBox 
        title={t('signin.login')}
        splitBar={true}
        inputs={[
          {
            label: t('labels.email'), 
            type: 'e-mail', 
            id: 'idEmail',
            placeholder: t('placeholders.email_example'),
            value: email,
            setValue: setEmail,
            error: errorEmail,
            errorMessage: errorEmailMessage,
            setErro: setErrorEmail,
          },
          {
            label: t('labels.password'), 
            type: 'password',
            id: 'idPassword',
            showPassword: false,
            value: password,
            setValue: setPassword,
            error: errorPassword,
            errorMessage: errorPasswordMessage,
            setErro: setErrorPassword
          },
        ]}
        inputErro={
          errrorPasswordBoteria ? 
          [
          {
            label: t('labels.password_boteria'), 
            type: 'password',
            id: 'idPasswordBoteria',
            showPassword: false,
            value: passwordBoteria,
            setValue: setPasswordBoteria,
            error: errorPassword,
            errorMessage: errorPasswordMessage,
            setErro: setErrorPassword,
            errorBoteriaInput: errrorPasswordBoteria
          }
          ]
          :
          []
        }
        links={
          email?.includes('@') && 
          email?.includes('.') ? 
          [
            {
              text: t('links.forgot_password'),
              to: `recuperar-senha/${email}`,
              title: t('labels.recovery_password_email')
            }
          ] : 
          [
            {
              text: t('links.forgot_password'),
              to: `/`,
              title: t('labels.recovery_password_email')
            }
          ]
        }
        setTokenRecaptcha={setTokenRecaptcha}
        buttons={[
          {
            text: t('buttons.create_account'),
            on: true,
            function: '',
            to: url[4] === undefined ?
            '/signup' : 
            url[4] === 'rd' ? 
            `/signup/rd/${url[5]}/${url[6]}/${url[7]}` :
            `/signup/nuvemshop/${url[5]}/${url[6]}`
          },
          {
            text: t('buttons.confirm'),
            on: false,
            function: Signin
          }
        ]}
      />

      <AlterLanguage />

      <PoweredBy />
    </Container>
  );
}

export default Login;