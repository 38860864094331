import api from '../api';

export const boteriaLoginService = async (user) => {
	const loginBoteriaAndCreateAccount = await api.post('partner/user/boteria/login', {
		user
	}).then(async (result) => {
		if(result.data.user.selectedOrg || result.data.user.claimIsRoot){
			return result.data
		} else {
			const login = await api.post('partner/user/login', {
				email: user.email,
				password: user.password,
				tokenBoteria: result.data.token
			}).then(resultLogin => {
				return resultLogin;
			}).catch(err => {
				return err;
			});
	
			return login;
		}
	})
	.catch(err => {
		window.location.href = '/';
	});

	return loginBoteriaAndCreateAccount;
}