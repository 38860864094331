import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, LanguageUsed, BoxOptions, Option } from './styles';

import { IoIosArrowDown } from 'react-icons/io';

function AlterLanguage() {

  const { i18n } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);

  const [boxOption, setBoxOption] = useState(false);
  const [language, setLanguage] = useState('Português (Brasil)');
  const [modeColor, setModeColor] = useState(modeColorState);

  const handleChangeLanguage = (newLanguage, value) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(value);
    setBoxOption(false);
  }

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  return (
    <Container>
      <LanguageUsed
        modeColor={modeColor}
        onClick={() => setBoxOption(boxOption ? false : true)}
      >
        {language}
        <IoIosArrowDown 
          onClick={() => setBoxOption(boxOption ? false : true)}
        />
      </LanguageUsed>
      {boxOption ? 
      <BoxOptions>
        <Option 
          modeColor={modeColor}
          onClick={() => handleChangeLanguage('Português (Brasil)', 'pt-br')}
        >
            Português (Brasil)
        </Option>
        <Option 
          modeColor={modeColor}
          onClick={() => handleChangeLanguage('English', 'en')}
        >
          English
        </Option>
      </BoxOptions> : ''}
    </Container>
  );
}

export default AlterLanguage;