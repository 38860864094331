import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #DADCE3;
  border-radius: 4px;
  padding: 24px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };

  code, code span, button svg {
    background: unset !important;
  }
`;

export const TitleComponent = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const InfoBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 400px;
  height: 92px;
  background: #C7F9ED;
  border: 1px solid #006074;
  border-radius: 4px;
  margin: 16px 0;

  svg {
    color: #006074;
    width: 40px;
    height: 40px;
    background: #C7F9ED;
  }
`;

export const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #006074;
  background: #C7F9ED;
`;

export const LabelScript = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.02em;
  font-feature-settings: 'ss01' on;
  margin-bottom: 8px;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const ButtomCopyCode = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 155px;
  height: 40px;
  border: 1px solid #254EDB;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #254EDB;
  cursor: pointer;
  margin: 16px 0;
`;