import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FiRefreshCw } from 'react-icons/fi';
import { ImAccessibility } from 'react-icons/im';
import { AiOutlineClose, AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';

import { 
  Container,
  HeaderChatbot,
  TitleBot,
  GroupIconlist,
  ContentMainChatbot,
  BotMessage,
  ClientMessage,
  SendMessageBox,
  ClipsIcon,
  InputGroupChatbot,
  InputChatbot,
  FooterChatbot
} from './styles';

import iconWebchat from './iconWebchat.js';

function PreviewChatbot(props) {

  const { t } = useTranslation();

  const titleBotReducer = useSelector(state => state.titleBotReducer);
  const mainColorReducer = useSelector(state => state.mainColorReducer);
  const mainTextColorReducer = useSelector(state => state.mainTextColorReducer);
  const secondaryColorReducer = useSelector(state => state.secondaryColorReducer);
  const secondaryTextColorReducer = useSelector(state => state.secondaryTextColorReducer);
  const sizeBotIconReducer = useSelector(state => state.sizeBotIconReducer);
  const botImageReducer = useSelector(state => state.botImageReducer);

  return (
    <Container>
      <HeaderChatbot 
        backgroundColor={mainColorReducer}
      >
        <TitleBot 
          backgroundColor={mainColorReducer}
          color={mainTextColorReducer}
        >
          {titleBotReducer}
        </TitleBot>
        <GroupIconlist 
          backgroundColor={mainColorReducer}
        >
          <FiRefreshCw />
          <ImAccessibility/>
          <AiOutlineClose />
        </GroupIconlist>
      </HeaderChatbot>
      <ContentMainChatbot>
        <BotMessage
          backgroundColor={secondaryColorReducer}
          color={secondaryTextColorReducer}
        >
          {t('code7_products.boteria.preview_chatbot_message_robo')}
        </BotMessage>
        <ClientMessage
          backgroundColor={mainColorReducer}
          color={mainTextColorReducer}
        >
          {t('code7_products.boteria.preview_chatbot_message_client')}
        </ClientMessage>
      </ContentMainChatbot>
      <SendMessageBox>
        <ClipsIcon>
          <AiOutlinePaperClip />
        </ClipsIcon>
        <InputGroupChatbot>
          <InputChatbot placeholder={t('code7_products.boteria.preview_chatbot_placeholder')} />
          <AiOutlineSend />
        </InputGroupChatbot>
      </SendMessageBox>
      <FooterChatbot>
        {t('code7_products.boteria.preview_chatbot_powered_by')}
      </FooterChatbot>
      <img 
        src={botImageReducer} 
        alt="Foto do bot"
        style={{width: `80px`, height: '80px'}}
      />
    </Container>
  );
}

export default PreviewChatbot;