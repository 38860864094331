import api from '../api';

export const allCompanysService = async (token) => {
	const allCompanys = await api.get('partner/bot/companies', {
    headers: {
      token: token
    }
  })
	.then(result => {
		return result.data
	})
	.catch(err => {
		return err;
	});
	return allCompanys;
}