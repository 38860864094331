import styled from 'styled-components';

export const Container = styled.div`
  width: 376px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  box-shadow: 0px 2px 4px rgba(24, 39, 75, 0.12), 0px 4px 4px rgba(24, 39, 75, 0.08);
  border-radius: 4px;

  > hr {
    border: 1px solid var(--split-bar-box);
    width: 100%;
  }
`;

export const Title = styled.div`
  background: var(--box);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-box-light)' :
    'var(--title-box-dark)'};
  padding: 24px;
`;

export const DescriptionBox = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--label-input-light)' :
    'var(--label-input-dark)'};
  padding: 24px 24px 14px;
  background: var(--box);
`;

export const SubDescriptionBox = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.033em;
  font-feature-settings: 'ss01' on;
  color: #5A5D68;
  padding: 0 24px;
  width: 328px;
  background: var(--box);
  margin-top: -15px;
  margin-left: 5px;
`;

export const InputGroup = styled.div`
  background: var(--box);
  padding: 24px;

  > div, > div div {
    background: var(--box);
  }
`;

export const Label = styled.div`
  background: var(--box);
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.02em;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--label-input-light)' :
    'var(--label-input-dark)'};
`;

export const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 120%;
  font-size: 12px;
  display: block;
  margin-top: 4px;
  color: ${props => 
    props.modeColor === 'Light Mode' ?
    'var(--border-input-error-light)' : 
    'var(--border-input-error-dark)'
  };
  margin-top: -10px;
  margin-left: 2px;
  margin-bottom: 20px;
`;

export const LinkGroup = styled.div`
  background: var(--box);
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;

  > a {
    background: var(--box);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 114%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    font-feature-settings: 'ss01' on;
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-links-light)' :
    'var(--sub-links-dark)'};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: ${props => props.buttonsLenght === 1 ? 
    'flex-end' : 
    'space-between'
  };
  background: var(--box);
  margin-bottom: 24px;
  padding: 0 24px;
`;

export const SelectedGroup = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 0 24px;
`;

export const DivGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
  width: 328px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  margin-bottom: 10px;

  svg {
    color: #979AA5;
    cursor: pointer;
    width: 18px;
    height: 18px;
    font-size: 18px;
    background: #FFFFFF;
  }
`;

export const SelectDiv = styled.input`
  background: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: black;
  width: 100%;
`;

export const CompanyOptions = styled.div`
  padding: 8px;
  cursor: pointer;
  background: var(--background-button-off-light);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;

  :hover {
    background: 
      ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? 
        'var(--sub-links-light)' : 
        'var(--background-alter-language-dark)')
      };
    color: var(--background-button-off-light);
  }
`;

export const CompanyBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
  margin-top: -5px;
  position: absolute;
  width: 328px;
  height: 129px;
  overflow: auto;
`;