import styled from 'styled-components';

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 27px;

  > a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143%;
    text-align: center;
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-links-light)' : 
    'var(--sub-links-dark)'};
    text-decoration: none;
    margin-left: -7px;
    cursor: pointer;
  }
`;

export const PoweredByComponent = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  text-align: center;
  letter-spacing: 0.054em;
  color: var(--color-powered-by);
  margin-bottom: 11px;
  margin-top: 90px;

  span {
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-links-light)' : 
    'var(--sub-links-dark)'};
  }
`;
