import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loader-spinner";

import { Container } from './styles';

import Logo from '../../../components/Items/Logo';
import FormBox from '../../../components/Items/FormBox';
import AlterLanguage from '../../../components/Items/AlterLanguage';
import PoweredBy from '../../../components/Items/PoweredBy';

import { toast } from '../../../components/Items/CustomToast';
import { RecoveryPasswordAction } from '../../../store/Auth/RecoveryPassword/RecoveryPassword.action';

function Login() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const recoveryPasswordReducer = useSelector(state => state.recoveryPasswordReducer);

  const [lastPassword, setLastPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [errorNewPasswordMessage, setErrorNewPasswordMessage] = useState();
  const [tokenRecaptcha, setTokenRecaptcha] = useState(0);
  const [confirmNewPassword, setConfirmeNewPassword] = useState();
  const [phone, setPhone] = useState();
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPhoneMessage, setErrorPhoneMessage] = useState();
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState(false);
  const [errorConfirmNewPasswordMessage, setErrorConfirmNewPasswordMessage] = useState();
  const [loader, setLoader] = useState(false);

  const url = window.location.href.split('/');

  useEffect(() => {
    if(recoveryPasswordReducer.success !== 0) {
      if(recoveryPasswordReducer.success) {
        toast.success(
          t('toast.success.correct'),
          t('toast.success.password_updated')
        );
  
        setTimeout(() => {
          window.location.href = `/`;          
        }, 2000);
      } else {
        if(recoveryPasswordReducer.message === 'user_not_found' || recoveryPasswordReducer.message === 'no_token_provider') {
          if(recoveryPasswordReducer.message === 'user_not_found') {
            toast.error(
              t('toast.errors.oops'),
              t(`errors.recoveryPasswordReducer_not_found`)
            );
          } else {
            toast.error(
              t('toast.errors.oops'),
              t(`errors.${recoveryPasswordReducer.message}`)
            );
          }
        } else {
          toast.error(
            t('toast.errors.oops'),
            t(`error`)
          );
        }
      }
    }
  }, [recoveryPasswordReducer]);

  const RecoveryPassword = () => {
    let errorConfirmPassword = false;
    let errorNewPassword = false;
    let errorPhone = false;

    if(!errorPhone && !phone) {
      errorPhone = true;
      setErrorPhone(true);
    }

    if(newPassword === undefined) {
      errorNewPassword = true;
      setErrorNewPassword(true);
    }

    if(confirmNewPassword === undefined) {
      errorConfirmPassword = true;
      setErrorConfirmNewPassword(true);
    } 
    
    if(newPassword !== confirmNewPassword) {
      errorNewPassword = true;
      errorConfirmPassword = true;
      setErrorNewPassword(true);
      setErrorConfirmNewPassword(true);
    } else if(tokenRecaptcha === 0) {
          toast.error(
            t('toast.errors.oops'),
            t('toast.errors.google_recaptcha_empty')
          );
        } 
    else {
      const user = {
        email: url[4],
        lastPassword: lastPassword,
        newPassword: newPassword,
        tokenRecaptcha: tokenRecaptcha,
        phone: phone,
        token: localStorage.getItem('API_TOKEN_PARTNERS')
      }

      dispatch(RecoveryPasswordAction(user));
    }
  }

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Logo />

      <FormBox 
        title={t('recovery_password.title')}
        splitBar={true}
        inputs={[
          {
            label: `${t('labels.phone_number')}*`, 
            type: 'phone', 
            id: 'idPhoneNumber',
            placeholder: t('placeholders.phone_number'),
            value: phone,
            setValue: setPhone,
            error: errorPhone,
            errorMessage: errorPhoneMessage,
            setErro: setErrorPhone,
          },
          {
            label: `${t('labels.new_password')}*`, 
            type: 'password',
            id: 'idNewPassword',
            showPassword: false,
            value: newPassword,
            setValue: setNewPassword,
            error: errorNewPassword,
            errorMessage: errorNewPasswordMessage,
            setErro: setErrorNewPassword
          },
          {
            label: `${t('labels.confirm_new_password')}*`, 
            type: 'password',
            id: 'idConfirmeNewPassword',
            showPassword: false,
            value: confirmNewPassword,
            setValue: setConfirmeNewPassword,
            error: errorConfirmNewPassword,
            errorMessage: errorConfirmNewPasswordMessage,
            setErro: setErrorConfirmNewPassword
          }
        ]}
        links={[]}
        setTokenRecaptcha={setTokenRecaptcha}
        buttons={[
          {
            text: t('buttons.toBack'),
            on: true,
            function: '',
            to: '/'
          },
          {
            text: t('buttons.confirm'),
            on: false,
            function: RecoveryPassword
          }
        ]}
      />

      <AlterLanguage />

      <PoweredBy />
    </Container>
  );
}

export default Login;