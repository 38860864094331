import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  Container, 
  BoxProduct,
  HeaderIntegration,
  ButtonActiveBot,
  Title,
  Subtitle,
  ImageProduct,
  LinkIntegration
} from './styles';

import imgBoxProduto from '../../assets/imgBoxProduto.png';

function ListCode7AppIntegrations(props) {

  const { t } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState)}, [modeColorState]);

  return (
    <Container modeColor={modeColor}>
      <BoxProduct active={true}>
        <HeaderIntegration>
          <ImageProduct modeColor={modeColor} src={imgBoxProduto} alt="Icone integração" />
          <ButtonActiveBot 
            modeColor={modeColor}
            botActive={props.integrationNuvemshopActive}
          >
            {props.integrationNuvemshopActive ? 
              t('code7_products.boteria.bot_on') : 
              t('code7_products.boteria.bot_off')
            }
          </ButtonActiveBot>
        </HeaderIntegration>
        <Title modeColor={modeColor}>
          {t('code7_products.integrations.nuvemshop_title')}
        </Title>
        <Subtitle modeColor={modeColor}>
          {t('code7_products.integrations.nuvemshop_description')}
        </Subtitle>
        <LinkIntegration modeColor={modeColor} onClick={
          () => window.location.href = 'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/Code7-Chatbot'
        }>
          {t('code7_products.integrations.buttom')}
        </LinkIntegration>
      </BoxProduct>

      <BoxProduct active={true}>
        <HeaderIntegration>
          <ImageProduct modeColor={modeColor} src={imgBoxProduto} alt="Icone integração" />
          <ButtonActiveBot 
            modeColor={modeColor}
            botActive={props.integrationRDActive}
          >
            {props.integrationRDActive ? 
              t('code7_products.boteria.bot_on') : 
              t('code7_products.boteria.bot_off')
            }
          </ButtonActiveBot>
        </HeaderIntegration>
        <Title modeColor={modeColor}>
          {t('code7_products.integrations.rd_title')}
        </Title>
        <Subtitle modeColor={modeColor}>
          {t('code7_products.integrations.rd_description')}
        </Subtitle>
        <LinkIntegration modeColor={modeColor} onClick={
          () => window.location.href = 'https://appstore.rdstation.com/pt-BR/apps/code7-app'
        }>
          {t('code7_products.integrations.buttom')}
        </LinkIntegration>
      </BoxProduct>
    </Container>
  );
}

export default ListCode7AppIntegrations;