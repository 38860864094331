import api from '../api';

export const extractMessagesService = async (dashboardTokenBoteria, botId, day, tokenPartners) => {
	const extractedMessage = await api.get('/partner/bot/extract/messages', {
		headers: {
			filter: day,
			dashboardTokenBoteria: dashboardTokenBoteria,
			botId: botId,
			authorization: 'Bearer' + '' + tokenPartners
		}
	}).then(result => {
		return result.data;
	}).catch(error => {
		return error.data;
	});

	return extractedMessage;
}