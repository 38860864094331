import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';

import { toast } from '../../../components/Items/CustomToast';

import { changeTitleBotAction } from '../../../store/Boteria/ChangeSettingsBot/TitleBot/TitleBot.action';
import { changeMainColorAction } from '../../../store/Boteria/ChangeSettingsBot/MainColor/MainColor.action';
import { changeMainTextColorAction } from '../../../store/Boteria/ChangeSettingsBot/MainTextColor/MainTextColor.action';
import { changeSecondaryColorAction } from '../../../store/Boteria/ChangeSettingsBot/SecondaryColor/SecondaryColor.action';
import { changeSecondaryTextColorAction } from '../../../store/Boteria/ChangeSettingsBot/SecondaryTextColor/SecondaryTextColor.action';
import { changSizeBotIconAction } from '../../../store/Boteria/ChangeSettingsBot/SizeBotIcon/SizeBotIcon.action';
import { changBotImageAction } from '../../../store/Boteria/ChangeSettingsBot/BotImage/BotImage.action';

import { 
  Container,
  Header,
  EditPhotoBot,
  LabelInput,
  InputGroup,
  InputOption,
  InputOptionColor,
  CircleColor,
  NumberOfCaracter,
  SizeIconBot,
  InputRange,
  ModalAlterColor,
  ButtomModalColor
} from './styles';

import iconWebchat from './iconWebchat.js';

function BotOptions(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modeColorState = useSelector(state => state.changeModeColor);
  const titleBotReducer = useSelector(state => state.titleBotReducer);
  const botImageReducer = useSelector(state => state.botImageReducer);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [numberOfCaracterState, setNumberOfCaracterState] = useState(0);
  const [pickerColor, setPickerColor] = useState('');
  const [piker, setPicker] = useState('');
  const [modalOnOrOff, setModalOnOrOff] = useState(false);
  const [titleBot, setTitleBot] = useState(titleBotReducer);
  const [changeTitleBot, setChangeTitleBot] = useState(false);
  const [mainColor, setMainColor] = useState();
  const [changeMainColor, setChangeMainColor] = useState(false);
  const [mainTextColor, setMainTextColor] = useState();
  const [changeMainTextColor, setChangeMainTextColor] = useState(false);
  const [secondaryColor, setSecondaryColorState] = useState();
  const [changeSecondaryColor, setChangeSecondaryColor] = useState(false);
  const [secondaryTextColor, setSecondaryTextColorState] = useState();
  const [changeSecondaryTextColor, setChangeSecondaryTextColor] = useState(false);
  const [sizeBotIcon, setSizeBotIconState] = useState(80);
  const [changeSizeBotIcon, setChaneSizeBotIconState] = useState(false);
  const [iconBot, setIconBot] = useState();
  const [changeIconBot, setChangeIconBot] = useState(false);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  useEffect(() => {
    !changeTitleBot && setTitleBot(props.settings.titleBot);
    !changeMainColor && setMainColor(props.settings.mainColor);
    !changeMainTextColor && setMainTextColor(props.settings.mainTextColor);
    !changeSecondaryColor && setSecondaryColorState(props.settings.secondaryColor);
    !changeSecondaryTextColor && setSecondaryTextColorState(props.settings.secondaryTextColor);
    !changeSizeBotIcon && setSizeBotIconState(props.settings.sizeBotIcon);
    !changeIconBot && setIconBot(props.settings.imageBot);
  }, [props]);

  const item = {};

  const onChangeBotImage = (files, sizeBot) => {
    if (files && files.length > 0) {
      const file = files[0]
      if (!file.type) {
        toast.error(
          t('toast.errors.oops'),
          t('errors.type_image_undefined')
        );
      } else if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
        toast.error(
          t('toast.errors.oops'),
          t('type_image_incorrect')
        );
      } else {
        const reader = new FileReader()
        reader.onload = (e) => {
          const image = new Image()
          image.src = e.target['result']
          image.onload = () => {
            // if(image.width > Number(sizeBot) || image.height > Number(sizeBot)){
            //   toast.error(
            //     t('toast.errors.oops'),
            //     t('errors.size_image')
            //   );
            // } else {
              props.settings.setImageBot(e.target['result']);
              setIconBot(e.target['result']);
              dispatch(changBotImageAction(e.target['result']));
              toast.success(
                t('toast.success.correct'),
                t('toast.success.image_profile')
              );
            // }
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const onOpenColorPicker = pickerParam => {
    if (pickerParam === 'main') {
      setPickerColor(props.settings.mainColor);
    }

    if(pickerParam === 'secondary'){
      setPickerColor(props.settings.secondaryColor);
    }

    if(pickerParam === 'mainText'){
      setPickerColor(props.settings.mainTextColor);
    }
    
    if(pickerParam === 'secondaryText'){
      setPickerColor(props.settings.secondaryTextColor);
    }

    setPicker(pickerParam)
}

  const onPickerColorChanged = color => {
    if (piker === 'main') {
      props.settings.setMainColor(color.hex);
      setPickerColor(color.hex);
      setMainColor(color.hex);
      dispatch(changeMainColorAction(color.hex));
    } 

    if (piker === 'secondary') {
      props.settings.setSecondaryColor(color.hex);
      setPickerColor(color.hex);
      setSecondaryColorState(color.hex);
      dispatch(changeSecondaryColorAction(color.hex));
    } 

    if (piker === 'mainText') {
      props.settings.setMainTextColor(color.hex);
      setPickerColor(color.hex);
      setMainTextColor(color.hex);
      dispatch(changeMainTextColorAction(color.hex));
    } 

    if (piker === 'secondaryText') {
      props.settings.setSecondaryTextColor(color.hex);
      setPickerColor(color.hex);
      setSecondaryTextColorState(color.hex);
      dispatch(changeSecondaryTextColorAction(color.hex));
    } 
}

  return (
    <Container modeColor={modeColor}>
      <Header modeColor={modeColor}>
        <img 
          src={botImageReducer} 
          alt="Imagem do bot"
          style={{width: `80px`, height: '80px'}}
        />
        <EditPhotoBot modeColor={modeColor} onClick={() => item.botFabInputFile.click()}>
          {t('code7_products.boteria.bot_options_edit_photo')}
        </EditPhotoBot>
        <input 
          style={{display: 'none'}} 
          type="file" 
          accept="image/png" 
          onChange={e => 
            onChangeBotImage(e.target.files, changeSizeBotIcon)
          } 
          ref={input => item.botFabInputFile = input}
        />
      </Header>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.bot_options_title_bot')}
      </LabelInput>
      <InputOption
        value={titleBot}
        onChange={(e) => {
          if(e.target.value.length < 29) {
            props.settings.setValue(e.target.value);
            setTitleBot(e.target.value);
            setNumberOfCaracterState(e.target.value.length);
            setChangeTitleBot(true);
            dispatch(changeTitleBotAction(e.target.value));
          }
        }}
      />
      <NumberOfCaracter modeColor={modeColor}>
        {numberOfCaracterState}/28
      </NumberOfCaracter>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.bot_options_main_color')}
      </LabelInput>
      <InputGroup>
        <InputOptionColor
          modeColor={modeColor}
          value={mainColor}
          onChange={e => {
            props.settings.setMainColor(e.target.value);
            setMainColor(e.target.value);
            dispatch(changeMainColorAction(e.target.value));
            setChangeMainColor(true);
          }}
        />
        <CircleColor 
          backgroundColor={mainColor}
          onClick={() => {
            setModalOnOrOff(true);
            onOpenColorPicker('main');
          }}
        />
      </InputGroup>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.bot_options_secondary_color')}
      </LabelInput>
      <InputGroup>
        <InputOptionColor
          modeColor={modeColor}
          value={secondaryColor}
          onChange={e => {
            // props.settgins.setSecondaryColor(e.target.value);
            setSecondaryColorState(e.target.value);
            dispatch(changeSecondaryColorAction(e.target.value));
            setChangeSecondaryColor(true);
          }}
        />
        <CircleColor 
          backgroundColor={secondaryColor}
          onClick={() => {
            setModalOnOrOff(true);
            onOpenColorPicker('secondary')
          }}
        />
      </InputGroup>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.bot_options_main_text_color')}
      </LabelInput>
      <InputGroup>
        <InputOptionColor
          modeColor={modeColor}
          value={mainTextColor}
          onChange={e => {
            props.settings.setMainTextColor(e.target.value);
            setMainTextColor(e.target.value);
            dispatch(changeMainTextColorAction(e.target.value));
            setChangeMainTextColor(true);
          }}
        />
        <CircleColor 
          backgroundColor={props.settings.mainTextColor}
          onClick={() => {
            setModalOnOrOff(true);
            onOpenColorPicker('mainText')
          }}
        />
      </InputGroup>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.bot_options_secondary_text_color')}
      </LabelInput>
      <InputGroup>
        <InputOptionColor
          modeColor={modeColor}
          value={secondaryTextColor}
          onChange={e => {
            props.settings.setSecondaryTextColor(e.target.value);
            setSecondaryTextColorState(e.target.value);
            dispatch(changeSecondaryTextColorAction(e.target.value));
            setChangeSecondaryTextColor(true);
          }}
        />
        <CircleColor 
          backgroundColor={secondaryTextColor}
          onClick={() => {
            setModalOnOrOff(true);
            onOpenColorPicker('secondaryText')
          }}
        />
      </InputGroup>
      <LabelInput modeColor={modeColor}>
        {t('code7_products.boteria.bot_options_size_icon')}
      </LabelInput>
      <SizeIconBot modeColor={modeColor}>
        <InputOption
          value={sizeBotIcon}
          lengthSmall={true}
          onChange={e => {
            props.settings.setSizeIconBot(e.target.value);
            setSizeBotIconState(e.target.value);
            setChaneSizeBotIconState(true);
            dispatch(changSizeBotIconAction(e.target.value));
          }}
        />
        <InputRange
          value={sizeBotIcon}
          min="40" 
          max="100"
          type="range" 
          onChange={e => {
            props.settings.setSizeIconBot(e.target.value);
            setSizeBotIconState(e.target.value);
            setChaneSizeBotIconState(true);
            dispatch(changSizeBotIconAction(e.target.value));
          }}
        />
      </SizeIconBot>
      {modalOnOrOff ? 
      <ModalAlterColor modeColor={modeColor}>
        <SketchPicker
          color={item.pickerColor}
          onChangeComplete={color => onPickerColorChanged(color)}
        />
        <ButtomModalColor 
          modeColor={modeColor}
          onClick={() => setModalOnOrOff(false)}
        >
          Ok
        </ButtomModalColor>
      </ModalAlterColor>
      : ''}
    </Container>
  );
}

export default BotOptions;