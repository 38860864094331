import api from '../api';

export const loginService = async (user) => {
	const login = await api.post('partner/user/login', {
		email: user.email,
		password: user.password,
		tokenReCaptcha: user.tokenRecaptcha,
		origin: user.origin,
		accessToken: user.accessToken,
		code: user.code,
		userIdStore: user.userIdStore,
		refreshToken_rd: user.refreshToken_rd,
		organizationId: user.organizationId,
		companyId: user.companyId,
		dataBoteria: user.dadosBoteria,
		tokenBoteria: user.organizationId ? user.tokenBoteria : undefined,
		passwordBoteria: user.passwordBoteria,
		errrorPasswordBoteria: user.errrorPasswordBoteria
	}).then(result => {
		return result.data;
	})
	.catch(err => {
		return err.response.data;
	});

	return login;
}