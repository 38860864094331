import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RiArrowGoBackFill } from 'react-icons/ri'

import { ButtonComponent } from './styles';

function Button(props) {

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  return (
    <ButtonComponent
      modeColor={modeColor}
      on={props.on}
      onClick={props.function}
      full={props.full}
    >
      <Link
        modeColor={modeColor}
        to={props.to}
      > 
        {props.icon ? <RiArrowGoBackFill modeColor={modeColor}/> : ''}
        {props.text}
      </Link>
    </ButtonComponent>
  );
}

export default Button;