import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

import { getUser } from '../../store/UserData/UserData.action';
import { updateUserBoteriaAction } from '../../store/Boteria/UpdateUserBoteria/UpdateBoteria.action';

import Menu from '../../components/Items/Menu';
import ContainerPage from '../../components/Items/ContainerPage';
import ListCode7Products from '../../components/ListCode7Products';

function Home() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.userData);

  useEffect(() => {
    localStorage.removeItem('DADOS_USER_BOTERIA');
    localStorage.removeItem('USER_CONFIG');
    localStorage.removeItem('PAGE_ORIGIN');
  }, []);

  useEffect(() => {
    dispatch(getUser(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));
    if(userData.integrations !== undefined) {
      userData.integrations.map(integration => {
        if(integration.name === 'rd'){
          dispatch(updateUserBoteriaAction(
            integration.code,
            userData,
            localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA'),
            localStorage.getItem('API_TOKEN_PARTNERS')
          ));
        } else if(integration.name === 'nuvemshop' && localStorage.getItem('USER_NUVEMSHOP')){
          localStorage.setItem('USER_NUVEMSHOP', integration.userIdStore);
        }
      });

      if(userData.prefererLanguage){
        if(userData.prefererLanguage === 'English'){
          i18n.changeLanguage('en');
        }

        if(userData.prefererLanguage === 'Português (Brasil)'){
          i18n.changeLanguage('pt-br');
        }
      }
    }
  }, [userData.data]);

  return (
    <Container>
      <Menu/>
      <ContainerPage
        title={t('code7_products.title')}
        subtitle={t('code7_products.subtitle')}
        hr={true}
      >
        <ListCode7Products />
      </ContainerPage>
    </Container>
  );
}

export default Home;