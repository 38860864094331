import api from '../api';

export const recoveryPasswordService = async (user) => {
	const recoveryPassword = await api.post('partner/user/recovery-password', {
		email: user.email,
		lastPassword: user.lastPassword,
		tokenReCaptcha: user.tokenRecaptcha,
		newPassword: user.newPassword,
		phone: user.phone,
		token: user.token
	}).then(result => {
		return result.data;
	})
	.catch(err => {
		return err.message;
	});
	return recoveryPassword;
}