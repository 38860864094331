import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Container, PoweredByComponent } from './styles';

function PoweredBy() {

  const { t } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  return (
    <Container modeColor={modeColor}>
      <PoweredByComponent modeColor={modeColor}>
        POWERED BY <span>code7</span>
      </PoweredByComponent>
      <Link 
        to="https://code7.com/wp-content/uploads/2021/04/Code7-Politica-de-Protecao-de-Dados.pdf-D4Sign.pdf">
          {t('links.privacy_policy')}
      </Link>
    </Container>
  );
}

export default PoweredBy;