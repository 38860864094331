import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import { Container, InputComponent } from './styles';

function Input(props) {

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  const tooglePassword = () => {
    let inputPassword = document.getElementById(props.id);

    if(inputPassword.type === 'password') {
      inputPassword.type = 'text';
      setShowPassword(true);
    } else {
      inputPassword.type = 'password';
      setShowPassword(false);
    }
  }

  return (
    <Container modeColor={modeColor} error={props.error}>
      <InputMask 
        mask={props.type === 'phone' ? '(99) 9 9999-9999' : ''}
        id={props.id}
        modeColor={modeColor}
        disabled={props.disabled}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);

          if(
            props.type === 'e-mail' && 
            e.target.value.includes('@') && 
            e.target.value.includes('.')
          ){
            props.setErro(false);
          }

          if(props.type === 'text' && e.target.value.length > 2) {
            props.setErro(false);
          }

          if(props.type === 'phone' && e.target.value.length > 12) {
            props.setErro(false);
          }

          if(props.type === 'password' && e.target.value.length > 5) {
            props.setErro(false);
          }
        }}
      />
      {props.type === 'password' ?
        showPassword ? 
        <AiFillEye onClick={tooglePassword}/> : 
        <AiOutlineEyeInvisible onClick={tooglePassword}/> : 
        ''
      }
    </Container>
  );
}

export default Input;