import styled from 'styled-components';

export const Container = styled.div`
  padding: 85px 0 85px 24px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  flex-wrap: wrap;
`;

export const WarningNoBots = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
`;

export const BoxBot = styled.div`
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 232px;
  height: 164px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  border: 1px solid #DADCE3;
  border-radius: 4px;
  cursor: pointer;
`;

export const HeaderBox = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #d6e4ff;
  }

  svg {
    color: #254edb;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    background: #d6e4ff;
  }
`;

export const ButtonActiveBot =  styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  height: 23px;
  background: ${props => props.botActive ? '#E3F8CC' : '#f95454'};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.033em;
  font-feature-settings: 'ss01' on;
  color: ${props => props.botActive ? '#19660A' : 'white'};
`;

export const TitleBot = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const ButtonOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 108px;
  height: 32px;
  background: #F3F5F9;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #254EDB;
  cursor: pointer;

  img {
    width: 16px;
    height: 18px;
    object-fit: contain;
    background: #F3F5F9;
  }
`;

export const ActiveOrDisabledBotBox = styled.div`
  background: #FFFFFF;
  border: 1px solid #DADCE3;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin-top: -10px;
  border-radius: 4px;
  z-index: 10;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 134px;
  height: 36px;
  background: #FFFFFF;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #5A5D68;

  svg {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
  }

  &:hover {
    background: #D6E4FF;

    svg {
      color: #254EDB;
      background: #D6E4FF;
    }
  }
`;