import api from '../api';

export const publishBotService = async (email, botId, tokenPartners) => {
	const publishBot = await api.post('/partner/bot/publish', {
		botId: botId,
		email: email
	}, {
		headers: {
			authorization: 'Bearer' + ' ' + tokenPartners
		}
	}).then((resultPublish) => {
		return resultPublish.data;
	}).catch((err) => {
		return {
			id: 0
		}
	});

	return publishBot;
}