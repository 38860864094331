import api from '../api';

export const updateUserBoteriaService = async (code, userData, token, tokenPartners) => {
	let updateCode = '';

	if(code !== undefined){
		updateCode = await api.post('partner/user/update-code', {
			code: code,
			userData: userData,
			token: token
		}, {
			headers: {
				authorization: 'Bearer' + ' ' + tokenPartners
			}
		}).then(async (result) => {
			return result.data;
		})
		.catch(err => {
			return err;
		});
	}

	return updateCode;
}