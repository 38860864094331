import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? '#f3f5f9' : '#000000')};
    transition: background 0.3s;
  }

  html, body, #root {
    height: 100%;
  }

  *, button, input, select {
    border: 0;
    outline: 0;
    font-family: 'Archivo', -apple-system, sans-serif;
  }

  :root {
    --neutral-3: #5a5d68;
    --title-logo-light: #35373F;
    --title-logo-dark: #FFFFFF;
    --sub-title-logo-dark: #FFFFFF;
    --sub-title-logo-light: #5A5D68;
    --title-content-page-light: #030303; 
    --title-content-page-dark: #FFFFFF; 
    --box-light: #FFFFFF;
    --box-dark: #2B2A34;
    --background-menu-dark: #4e4d54;
    --background-menu-light: #f3f5f9;
    --title-box-light: #35373F;
    --title-box-dark: #FFFFFF;
    --split-bar-box: #DADCE3;
    --label-input-light: #5A5D68;
    --label-input-dark: #ABAFBA;
    --placeholder-input-dark: #FFFFFF;
    --placeholder-input-light: #979AA5;
    --border-input: #979AA5;
    --border-input-active: #6690ff;
    --border-input-active-error: #6690ff;
    --border-input-error-light: #871821;
    --border-input-error-dark: #FF3729;
    --backgroun-input-error: #fae0d2;
    --backgroun-input-dark: #000000;
    --sub-links-light: #102693;
    --sub-links-dark: #6690FF;
    --background-alter-language-dark: #494C5A;
    --alter-language-light: #102693;
    --alter-language-dark: #DADCE3;
    --alter-language-option-light: #35373f;
    --background-button-off-light: #FFFFFF;
    --background-button-off-dark: #494C5A;
    --border-button-off-light: #102693;
    --border-button-off-dark: #494C5A;
    --color-background-off-light: #102693;
    --color-background-off-dark: #F3F5F9;
    --background-button-on-light: #102693;
    --background-button-on-dark: #254EDB;
    --color-background-on-light: #FFFFFF;
    --color-powered-by: #5A5D68;
    --status-blue: #3366FF;
    --status-green: #38b878;
    --status-orange: #f9a75b;
    --status-purple: #a638b8;
    --status-red: #f9675b;
    --status-yellow: #f9d65b;
  }
`;