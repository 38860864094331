import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 34px;
  display: flex;
  justify-content: center;
`;

export const LanguageUsed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: 
    ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? 'var(--alter-language-light)' : 'var(--alter-language-dark)')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  cursor: pointer;
  background: 
    ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? '' : 'var(--background-alter-language-dark)')};
  width: 187px;
  height: 40px;

  svg {
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: 
      ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? 'var(--alter-language-light)' : 'var(--alter-language-dark)')};
    cursor: pointer;
    background: 
      ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? '' : 'var(--background-alter-language-dark)')};
  }
`;

export const BoxOptions = styled.div`
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
  padding: 8px;
  background: var(--background-button-off-light);
  padding: 8px;
  margin-top: 35px;
  position: absolute;
`;

export const Option = styled.div`
  background: var(--background-button-off);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
  white-space: nowrap;
  outline: none;
  width: 174px;
  font-size: 14px;
  color: var(--alter-language-option-light);
  user-select: none;
  border-radius: 4px;

  :hover {
    background: 
      ${props => (props.modeColor === 'Light Mode' || props.modeColor === null ? 
        'var(--sub-links-light)' : 
        'var(--background-alter-language-dark)')
      };
    color: var(--background-button-off-light);
  }
`;