import api from '../api';

export const getScriptNuvemshopService = async (userIdStore, accessToken, tokenPartners) => {
	const getScript = await api.get('/partner/nuvemshop/script', {
		headers: {
      userIdStore: userIdStore,
			accessToken: accessToken,
			authorization: 'Bearer' + ' ' + tokenPartners
		}
	}).then(result => {
		return result.data;
	}).catch(error => {
		return error.data;
	});

	return getScript;
}