import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  Container,
  Title,
  Description,
  ContentSettings
} from './styles';

import BotOptions from '../BotOptions';
import PreviewChatbot from '../PreviewChatbot';

function ContainerBotSettings(props) {

  const { t } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  return (
    <Container modeColor={modeColor}>
      <Title modeColor={modeColor}>
        {t('code7_products.boteria.container_settings_bot_title')}
      </Title>
      <Description modeColor={modeColor}>
        {t('code7_products.boteria.container_settings_bot_description')}
      </Description>
      <ContentSettings modeColor={modeColor}>
        <BotOptions settings={props.settings}/>
        <PreviewChatbot settings={props.settings}/>
      </ContentSettings>
    </Container>
  );
}

export default ContainerBotSettings;