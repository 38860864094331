import api from '../api';

export const getReporstService = async (botId, dashboardTokenBoteria, tokenPartners) => {
	const GetReportsReducer = await api.get('/partner/bot/reports', {
		headers: {
      botId: botId,
			dashboardTokenBoteria: dashboardTokenBoteria,
			authorization: 'Bearer' + ' ' + tokenPartners
		}
	}).then(result => {
		return result.data;
	}).catch(error => {
		return error.data;
	});

	return GetReportsReducer;
}