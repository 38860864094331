import styled from 'styled-components';

export const Container = styled.div`

  display: flex;
  align-items: center;

  svg {
    font-weight: bold;
    cursor: pointer;
    margin-left: -40px;
    margin-top: -15px;
    color: var(--label-input-dark);
    width: 30px;
    height: 20px;
    background: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--box-light)' : 
      'transparent'
    };
  }

  input {
    width: 328px;
    height: 40px;
    left: 0px;
    top: 0px;
    background: ${props => props.error ? 
    'var(--backgroun-input-error)' : 
    props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--box-light)' : 
    'var(--backgroun-input-dark)'};
    border: ${props => 
      props.error ? 
      props.modeColor === 'Light Mode' ?
      '1px solid var(--border-input-error-light)' : 
      '1px solid var(--border-input-error-dark)' : 
      '1px solid var(--border-input)'
    };
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 10px;
    color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'black' : 
    '#FFFFFF'};
    };
  }

  input:focus {
    box-shadow: 0 0 0 3px var(--border-input-active);
  }
`;
