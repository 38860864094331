import styled from 'styled-components';

export const Container = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
`;

export const Header = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;

  img {
    background: ${
      props => props.modeColor === 'Light Mode' || props.modeColor === null ?
      'var(--box-light)' :
      'var(--box-dark)'
    };
  }
`;

export const EditPhotoBot = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  height: 40px;
  background: #F3F5F9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #254EDB;
  cursor: pointer;
`;

export const LabelInput = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.02em;
  font-feature-settings: 'ss01' on;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    'var(--sub-title-logo-dark)'
  };
  margin-top: 16px;
`;

export const InputGroup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 476px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  margin-top: 8px;
`;

export const InputOption = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: ${props => props.lengthSmall ? '50px' : '476px'};
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  margin-top: 8px;
`;

export const InputOptionColor = styled.input`
  background: #FFFFFF;
  width: 415px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--sub-title-logo-light);
`;

export const CircleColor = styled.div`
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  background: ${props => props.backgroundColor};
  border: 1px solid #979AA5;
  border-radius: 50px;
  cursor: pointer;
`;

export const NumberOfCaracter = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-weight: 400;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.033em;
  font-feature-settings: 'ss01' on;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    'var(--sub-title-logo-dark)'
  };
  margin-top: 4px;
`;

export const SizeIconBot = styled.div`
  display: flex;
  align-items: center;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
`;

export const InputRange = styled.input`
  box-sizing: border-box;
  width: 410px;
  height: 0px;
  border: 4px solid #DADCE3;
  border-radius: 4px;
  margin-left: 16px;
`;

export const ModalAlterColor = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(36, 36, 48, 0.5) 0% 0% no-repeat padding-box;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  > div, 
  > div div:nth-child(2), 
  > div div:nth-child(2), 
  > div div:nth-child(3),
  > div div:nth-child(3) div,  
  > div div:nth-child(3) div div input,  
  > div div:nth-child(3) div div span,  
  > div div:nth-child(4) {
    background: unset;
  }
`;

export const ButtomModalColor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 89px;
  height: 40px;
  background: #254EDB !important;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 8px;
`;