import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CgWebsite } from 'react-icons/cg';
import { BiExit } from 'react-icons/bi';
import { FaRobot, FaStoreAlt } from 'react-icons/fa';
import { FiHeadphones } from 'react-icons/fi';
import { GiFamilyTree } from 'react-icons/gi';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { FcDataConfiguration } from 'react-icons/fc';

import { Container, MenuElement, SplitBarMenu, PhotoProfile } from './styles';

import Logo from '../Logo';
import profileImage from './profileImage.js';

function Menu(props) {

  const { t } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);
  const userData = useSelector(state => state.userData);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [currentPage, setCurrentPage] = useState('home');
  const [userName, setUserName] = useState(props.userName);
  const [imageProfileUser, setImageProfileUser] = useState(props.imageProfileUser);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  useEffect(() => {
    if(userData.data !== 'vazio' && props.userName === undefined){
      setUserName(userData.name);
    } else {
      setUserName(props.userName);
    }

    if(props.imageProfileUser === undefined){
      if(userData.profileImage !== null && userData.profileImage !== undefined){
        setImageProfileUser(userData.profileImage);
      } else {
        setImageProfileUser(profileImage.imageUser);
      }
    } else {
      setImageProfileUser(props.imageProfileUser);
    }
  }, [userData, imageProfileUser, props.imageProfileUser, props.userName]);

  useEffect(() => {setCurrentPage(window.location.href.split('/')[4]);}, [window.location.href]);

  return (
    <Container modeColor={modeColor}>
      <Logo />
      <MenuElement 
        status={true} 
        modeColor={modeColor} 
        activeMenu={currentPage === 'profile' ? true : false}
        onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/profile`}
      > 
       <PhotoProfile src={imageProfileUser} /> 
       <div>
        {userName}
       </div>
      </MenuElement>
      <MenuElement
        status={true} 
        modeColor={modeColor} 
        onClick={() => window.location.href = '/'}
      > 
       <BiExit /> 
       <div>
        {t('code7_products.profile.logout')}
       </div>
      </MenuElement>
      <SplitBarMenu />
      <MenuElement 
        status={true} 
        modeColor={modeColor} 
        activeMenu={currentPage === 'home' ? true : false}
        onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/home`}
      > 
       <CgWebsite /> 
       <div>
        {t('menu.title')}
       </div>
      </MenuElement>
      <MenuElement
        submenu={true}
        status={true} 
        modeColor={modeColor} 
        activeMenu={currentPage === 'boteria-page' ? true : false}
        onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/boteria-page`}
      > 
       <FaRobot /> 
       <div>
        {t('menu.boteria_name')}
       </div>
      </MenuElement>
      <MenuElement
        submenu={true}
        status={false} 
        modeColor={modeColor} 
        activeMenu={currentPage === 'omni' ? true : false}
        // onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/omni`}
      > 
       <FiHeadphones /> 
       <div>
        {t('menu.omni_name')} - {t('menu.coming_soon')}
       </div>
      </MenuElement>
      <MenuElement
        submenu={true}
        status={false} 
        modeColor={modeColor} 
        activeMenu={currentPage === 'journey-builder' ? true : false}
        // onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/journey-builder`}
      > 
       <GiFamilyTree /> 
       <div>
        {t('menu.journey_builder_name')} - {t('menu.coming_soon')}
       </div>
      </MenuElement>
      <SplitBarMenu />
      <MenuElement
        status={true} 
        modeColor={modeColor} 
        activeMenu={currentPage === 'integrations' ? true : false}
        onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/integrations`}
      > 
       <FcDataConfiguration /> 
       <div>
        {t('menu.integrations_title')}
       </div>
      </MenuElement>
      <MenuElement
        submenu={true}
        status={true} 
        modeColor={modeColor} 
        onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/integrations`}
      > 
       <FaStoreAlt /> 
       <div>
        {t('menu.integrations_nuvemshop')}
       </div>
      </MenuElement>
      <MenuElement
        submenu={true}
        status={true} 
        modeColor={modeColor} 
        onClick={() => window.location.href = `/${window.location.href.split('/')[3]}/integrations`}
      > 
       <AiOutlineFileAdd /> 
       <div>
        {t('menu.integrations_rd')}
       </div>
      </MenuElement>
    </Container>
  );
}

export default Menu;