import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #DADCE3;
  border-radius: 4px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 24px;
  margin: 16px 0;
`;

export const Title = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.75%;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
`;

export const Description = styled.div`
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  font-size: 14px;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--sub-title-logo-light)' : 
    'var(--sub-title-logo-dark)'
  };
`;

export const ContentSettings = styled.div`
  display: flex;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
`;