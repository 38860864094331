import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BsMoon, BsSun } from 'react-icons/bs';

import { ChangeModeColorAction } from '../../../store/ChangeModeColor/ChangeModeColor.action';

function Toggle() {

  const dispatch = useDispatch();
  
  const modeColorState = useSelector(state => state.changeModeColor);

  const [toggled, setToggled] = useState('Light Mode');
  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {
    const modeColorLocalStorage = localStorage.getItem('MODE_COLOR');

    if(modeColorLocalStorage || modeColorLocalStorage !== undefined) {
      dispatch(ChangeModeColorAction(modeColorLocalStorage))
      setToggled(modeColorLocalStorage);
    }
  }, [toggled]);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  return (
    <button
      style={{
        position: 'absolute',
        top: '10px',
        right: '20px',
        color: `${modeColor === 'Light Mode' || modeColor === null ? '#102693' : 'white'}`,
        background: `
          ${window.location.href.split('/').length === 4 ? 
            '' : 
            modeColor === 'Light Mode' || modeColor === null ? 'var(--box-light)' : 'var(--box-dark)'}`
      }}
      onClick={() => {
        if(toggled === 'Light Mode') {
          dispatch(ChangeModeColorAction('Dark Mode'))
          setToggled('Dark Mode');
          localStorage.setItem('MODE_COLOR', 'Dark Mode');
        } else {
          dispatch(ChangeModeColorAction('Light Mode'));
          setToggled('Light Mode');
          localStorage.setItem('MODE_COLOR', 'Light Mode');
        }
      }}  
    >
      {toggled === 'Light Mode' ? 
        <BsSun 
          style={{width: '30px',  height: '30px', cursor: 'pointer', background: 'transparent'}}
        /> : 
        <BsMoon 
          style={{width: '30px',  height: '30px', cursor: 'pointer', background: 'none'}}
        /> 
      }
    </button>
  );
}

export default Toggle;