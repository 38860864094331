import api from '../api';

export const updateOrgIdAndCompanyIdService = async (company, org, claimIsRoot, token) => {
	const updateOrgIdAndCompanyId = await api.post('partner/bot/update-org-company', {
    company: company,
    organization: org,
    claimIsRoot: claimIsRoot,
    token: token
  }).then(async (result) => {
    return result.data;
  })
  .catch(err => {
    return err;
  });

	return updateOrgIdAndCompanyId;
}