import api from '../api';

export const updateSettingsBotService = async (config, tokenPartners) => {
	const { 
		mainColor, 
		secondaryColor, 
		mainTextColor, 
		secondaryTextColor, 
		sizeBotIcon, 
		botIcon, 
		isActive,
		botId,
		token,
		headerName,
		userData
	} = config

	const updateSettings = await api.post('/partner/bot/update/settings', {
		mainColor,
		secondaryColor,
		mainTextColor,
		secondaryTextColor,
		sizeBotIcon,
		botIcon,
		isActive,
		botId,
		token,
		headerName,
		userData
	}, {
		headers: {
			authorization: 'Bearer' + ' ' + tokenPartners
		}
	}).then((result) => {
		return result.data;
	}).catch((err) => {
		return err.data;
	});

	return updateSettings;
}