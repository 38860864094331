import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GlobalStyles from '../styles/global.js';
import { BrowserRouter } from 'react-router-dom';

import Login from '../pages/Auth/Login';
import RecoveryPassword from '../pages/Auth/RecoveryPassword';
import CreateAccount from '../pages/Auth/CreateAccount';
import BoteriaAccountExisting from '../pages/Auth/BoteriaExistingAccount';
import AccountCreated from '../pages/Auth/AccountCreated';
import Home from '../pages/Home';
import Boteria from '../pages/Boteria/Boteria';
import BotSettings from '../pages/Boteria/BotSettings';
import IntegrationsPage from '../pages/IntegrationsPage';
import Profile from '../pages/Profile';
import SelectedOrganization from '../pages/Auth/SelectedOrganization';

function App() {

  const url = window.location.href.split('/');

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState('');

  useEffect(() => {
    if(modeColorState !== 'Light Mode' || modeColorState !== 'Dark Mode'){
      if(localStorage.getItem('MODE_COLOR')){
        setModeColor(localStorage.getItem('MODE_COLOR'));
      } else {
        setModeColor('Light Mode');
      }
    } else {
      setModeColor(modeColorState);
    }
  }, [modeColorState]);

  return (
    <div className="App">
      <GlobalStyles modeColor={modeColor}/>
      <BrowserRouter>
        <Router>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/recuperar-senha/:email' component={RecoveryPassword} />
            <Route exact path={url[4] === 'rd' ? "/signin/:origin/:user_id/:token/:code" : "/signin/:origin/:user_id/:token"} component={Login} />
            <Route exact path="/signup" component={CreateAccount} />
            <Route exact path={url[4] === 'rd' ? "/signup/:origin/:user_id/:token/:code" : "/signup/:origin/:user_id/:token"} component={CreateAccount} />
            <Route exact path="/boteria-existing-account" component={BoteriaAccountExisting} />
            <Route exact path={url[4] === 'rd' ? "/boteria-existing-account/:origin/:user_id/:token/:code" : "/signup/:origin/:user_id/:token"} component={BoteriaAccountExisting} />
            <Route exact path={"/account-created"} component={AccountCreated} />
            <Route exact path="/:origin/home" component={Home} />
            <Route exact path="/:origin/integrations" component={IntegrationsPage} />
            <Route exact path="/:origin/boteria-page" component={Boteria} />
            <Route exact path="/:origin/profile" component={Profile} />
            <Route exact path="/:origin/config/:botid" component={BotSettings} />
            <Route exact path="/selected-organization" component={SelectedOrganization} />
          </Switch>
        </Router>
      </BrowserRouter>
    </div>
  );
}

export default App;
