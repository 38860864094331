import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CustomContainerToast = styled(ToastContainer).attrs({
  // custom props
})`
  background: transparent;
  svg {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
  }

  .Toastify__toast {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    padding: 6px 12px;
    border-radius: 5px;
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };

    .custom-toastify-content {
      background: ${
      props => props.modeColor === 'Light Mode' ?
      'var(--box-light)' :
      'var(--box-dark)'
      };
      display: flex;
      flex-direction: column;
    }
    .custom-toastify-icon {
      background: ${
      props => props.modeColor === 'Light Mode' ?
      'var(--box-light)' :
      'var(--box-dark)'
      };
      margin-right: 10px;
    }
    .custom-toastify-title {
      background: ${
      props => props.modeColor === 'Light Mode' ?
      'var(--box-light)' :
      'var(--box-dark)'
      };
      color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
      '#3d4859' :
      'white'};
      font-size: 0.875rem;
      margin-bottom: 2px;
    }
    .custom-toastify-description {
      background: ${
      props => props.modeColor === 'Light Mode' ?
      'var(--box-light)' :
      'var(--box-dark)'
      };
      color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--neutral-3)' :
      'white'};
      font-size: 0.813rem;
      margin-bottom: 2px;
    }
  }

  .Toastify__toast--default {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
    border-left: 5px solid var(--status-blue);
  }

  .Toastify__toast--success {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
    border-left: 5px solid var(--status-blue);
  }

  .Toastify__toast--error {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
    border-left: 5px solid var(--status-red);
  }

  .Toastify__toast--warning {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
    border-left: 5px solid var(--status-orange);
  }

  .Toastify__close-button {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
    color: #858f9e;
  }

  .Toastify__toast-body {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
    margin: auto 0px;
    font-size: 1rem;
    flex: 1 1 0%;
    display: flex;
    align-items: center;
  }

  .Toastify__toast-body > div {
    background: ${
    props => props.modeColor === 'Light Mode' ?
    'var(--box-light)' :
    'var(--box-dark)'
    };
  }

  .Toastify__progress-bar {
    background: #3d48593d;
  }
`;
