import styled from 'styled-components';

export const ButtonComponent = styled.div`

  background: ${props => props.modeColor === 'Light Mode' ?
  'var(--box-light)' :
  'var(--box-dark)'};
  width: ${props => props.full ? '100%' : ''};

  > a {
    padding: 0 24px;
    cursor: pointer;
    border-radius: 4px;
    width: ${props => props.full ? '100%' : '152px'};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: ${props => props.on ? 
      props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--color-background-off-light)' : 
      'var(--color-background-off-dark)' : 
      'var(--color-background-on-light)'
    };
    background: ${props => props.on ? 
      props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--background-button-off-light)' : 
      'var(--background-button-off-dark)' : 
      props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--background-button-on-light)' : 
      'var(--background-button-on-dark)'
    };
    border: solid 1px ${props => props.on ? 
      props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--border-button-off-light)' : 
      'var(--border-button-off-dark)' : 
      props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--background-button-on-light)' : 
      'var(--background-button-on-dark)'
    };;
    text-decoration: none;
    text-align: center;
  }

  svg {
    color: ${props => props.on ? 
      props.modeColor === 'Light Mode' || props.modeColor === null ? 
      'var(--color-background-off-light)' : 
      'var(--color-background-off-dark)' : 
      'var(--color-background-on-light)'
    };
    background: transparent;
    margin-right: 10px;
  }
`;
