import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CopyBlock, dracula } from "react-code-blocks";
import { useTranslation } from 'react-i18next';

import { BsInfoSquareFill } from 'react-icons/bs';

import { 
  Container,
  TitleComponent,
  InfoBox,
  Info,
  LabelScript,
  ButtomCopyCode
} from './styles';

function CopyScriptToWebchat() {

  const { t } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  const codeHead = 
  `<!-- HEAD -->
  <link rel="stylesheet" href="https://app.boteria.com.br/cdn/widget_v2/widget.css" />
  <link rel="stylesheet" href="https://app.boteria.com.br/cdn/widget_v2/webchat.v2.css" />
  <script src="https://app.boteria.com.br/cdn/libs/showdown.min.js"></script>
  <script src="https://app.boteria.com.br/cdn/libs/axios.js"></script>
  <script src="https://app.boteria.com.br/cdn/libs/socket.io.js"></script>
  <script src="https://app.boteria.com.br/cdn/widget_v2/widget.js"></script> `

  const codeBody =
  `<!-- BODY -->
  <script>
    (function () {
      window.onload = function () {
        new BoteriaChat().withAppKey('${window.location.href.split('/')[5]}')
      }
    })()
  </script>`
 

  return (
    <Container modeColor={modeColor}>
      <TitleComponent modeColor={modeColor}>
        {t('code7_products.boteria.copy_script_webchat_title')}
      </TitleComponent>
      <InfoBox>
        <BsInfoSquareFill />
        <Info>
          {t('code7_products.boteria.copy_script_webchat_warning')}
        </Info>
      </InfoBox>
      <LabelScript modeColor={modeColor}>
        {t('code7_products.boteria.copy_script_webchat_paste_on_head')}
      </LabelScript>
      <CopyBlock
        language={'html'}
        text={codeHead}
        showLineNumbers={true}
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
      <LabelScript style={{marginTop: '20px'}} modeColor={modeColor}>
        {t('code7_products.boteria.copy_script_webchat_paste_on_body')}
      </LabelScript>
      <CopyBlock
        language={'html'}
        text={codeBody}
        showLineNumbers={true}
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
    </Container>
  );
}

export default CopyScriptToWebchat;