import api from '../api';

export const copyBotTemplateService = async (user) => {
	const copyBotTemplate = await api.post('partner/bot/copy-bot-template', {
    user: user
  })
	.then(result => {
		return result.data
	})
	.catch(err => {
		return err;
	});
	return copyBotTemplate;
}