import api from './api';

export const updateUser = async (userUpdate, tokenPartners) => {
  const updateUser = await api.post('partner/user/update', {
    userUpdate: userUpdate
  }, {
    headers: {
      authorization: 'Bearer' + ' ' + tokenPartners
    }
  })
  .then(async (result) => {return result.data})
  .catch(err => {return err;});

  return updateUser;
}