import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { Container } from './styles';

function GoogleRecaptcha(props) {

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  const SITE_KEY_RECAPCTHA =
  process.env.NODE_ENV?.toLowerCase() === 'development'
    ? '6Lcw-3gaAAAAACnU13Y68ItAlnE0K_DjiQTqw6QU'
    : '6LdUGqAaAAAAANhwx9wrLgp3JhNsDusTHj4f4DH6';

  return (
    <Container modeColor={modeColor}>
      <ReCAPTCHA
        sitekey={SITE_KEY_RECAPCTHA}
        onChange={(e) => {
          props.setToken(e);
        }}
      /> 
    </Container>
  );
}

export default GoogleRecaptcha;