import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loader-spinner";

import { Container } from './styles';

import { allCompanysAction } from '../../../store/Boteria/AllCompanys/AllCompanys.action';
import { allOrganizationsAction } from '../../../store/Boteria/AllOrganizations/AllOrganizations.action';
import { updateOrgIdAndCompanyIdAction } from '../../../store/Boteria/UpdateOrgIdAndCompanyId/UpdateOrgIdAndCompanyId.action';
import { create } from '../../../store/Auth/CreateUser/CreateUser.actions';
import { LoginAction } from '../../../store/Auth/Login/Login.action';
import { updateUserAction } from '../../../store/UpdateUser/UpdateUser.action';
import { CopyBotTemplate } from '../../../store/Boteria/CopyBotTemplate/CopyBotTemplate.action';

import Logo from '../../../components/Items/Logo';
import FormBox from '../../../components/Items/FormBox';
import AlterLanguage from '../../../components/Items/AlterLanguage';
import PoweredBy from '../../../components/Items/PoweredBy';

function SelectedOrganization() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allCompanies = useSelector(state => state.allCompanys);
  const allOrganizations = useSelector(state => state.allOrganizations);
  const updateOrgIdAndCompanyId = useSelector(state => state.updateOrgIdAndCompanyId);
  const userData = useSelector(state => state.user);
  const userLogin = useSelector(state => state.login);
  const [loader, setLoader] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [allCompaniesState, setCompaniesState] = useState('');
  const [allOrgsState, setOrgsState] = useState('');
  const [selectOrganization, setSelectOrganization] = useState('');
  const [orgranizationsCompany, setOrganizationsCompany] = useState('');
  const [selectCompany, setSelectCompany] = useState('');
  const [userConfigState, setUserConfigState] = useState(JSON.parse(localStorage.getItem('USER_CONFIG')))
  const [dataBoteriaState, setDataBoteria] = useState(JSON.parse(localStorage.getItem('DADOS_USER_BOTERIA')));

  useEffect(() => {
    dispatch(allCompanysAction(localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA')));
    dispatch(allOrganizationsAction(localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA')));

    if(allCompanies.allCompanies !== undefined){
      setCompaniesState(allCompanies.allCompanies);
      setOrgsState(allOrganizations.allOrganizations);
    }
  }, [allCompanies.success, allOrganizations.success]);

  useEffect(() => {
    if (companies?.length === 0 && allCompaniesState.length > 0) {
      const result = allCompaniesState.map((company) => {
        return {
          ...company,
          name: `${company?.fantasyName} - (${company?.emailResponsible})`,
        };
      });
      setCompanies(result);
    } 
  }, [allCompaniesState]);

  useEffect(() => {
    setSelectCompany(selectCompany);
  }, [selectCompany]);

  useEffect(() => {
    if(updateOrgIdAndCompanyId === 1){

      userConfigState.organizationId = selectOrganization._id;
      userConfigState.companyId = selectCompany._id;
      userConfigState.dadosBoteria = dataBoteriaState;

      if(localStorage.getItem('PAGE_ORIGIN') === 'Login Boteria'){
        dispatch(create(userConfigState));
      }

      if(localStorage.getItem('PAGE_ORIGIN') === 'Login'){
        dispatch(updateUserAction({
          organizations: selectOrganization._id,
          company: selectCompany._id,
          email: userConfigState.email
        }));

        userConfigState.tokenBoteria = localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA');
        dispatch(LoginAction(userConfigState));
      }
    }
  }, [updateOrgIdAndCompanyId]);

  useEffect(() => {
    if(userData.message === 'Usuário cadastrado com sucesso'){
      userConfigState.organizationId = selectOrganization._id;
      userConfigState.companyId = selectCompany._id;
      userConfigState.dadosBoteria = dataBoteriaState;
      userConfigState.tokenBoteria = localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA');
      dispatch(LoginAction(userConfigState));
    }
  }, [userData]);

  useEffect(() => {
    if(userLogin.message !== undefined && userLogin.message === 'success'){
      localStorage.setItem('API_TOKEN_PARTNERS', userLogin.token);
      localStorage.setItem('API_TOKEN_PARTNERS_BOTERIA', userLogin.tokenBoteria);
      localStorage.setItem('EMAIL', userLogin.response.email);

      setTimeout(() => {
          window.location.href = 
          `/${userLogin.response.originInitial !== undefined ? userLogin.response.originInitial : 'nuvemshop'}/home`;          
      }, 5000);
    } else {
      setLoader(false);
    }
  }, [userLogin]);

  const fetchOrgCompanys = (idCompany) => {
    if (idCompany) {
      let orgArray = [];
      
      orgArray = allOrgsState.filter((org) => {
        return org.companyId === idCompany;
      });

      setOrganizationsCompany(orgArray);
      setSelectOrganization(orgArray[0]);
    }
  };

  const handleChangeCompany = (value) => {
    setSelectCompany(value);
    if (selectCompany._id !== value._id) {
      setSelectCompany(value);
      fetchOrgCompanys(value._id);
    }
  };

  const handleChangeOrganization = (value) => {
    setSelectOrganization(value);
  };

  const handleSelectCompanyOrganization = async () => {
    let newCompany = '';
    let newOrg = '';

    if (selectCompany && selectOrganization) {
      const newModeObj = dataBoteriaState.user === undefined ? 
        dataBoteriaState.allDataUser : 
        dataBoteriaState.user.allDataUser.user
      
      await newModeObj.companies.map(company => {
        if(company === selectCompany._id){
          newCompany = selectCompany._id;
        }
      });

      await newModeObj.organizationIds.map(organization => {
        if(organization === selectOrganization._id){
          newOrg = selectOrganization._id;
        }
      });

      const user = {
        companyId: newCompany,
        organizationId: newOrg,
        data: dataBoteriaState.response,
        origin: dataBoteriaState.origin,
        accessToken: dataBoteriaState.accessToken,
        userIdStore: dataBoteriaState.userIdStore,
        code: dataBoteriaState.code
      }

      setLoader(true);
      dispatch(CopyBotTemplate(user));
      dispatch(
        updateOrgIdAndCompanyIdAction(
          selectCompany._id,
          selectOrganization._id,
          dataBoteriaState.claimIsRoot !== undefined ? 
            dataBoteriaState.claimIsRoot : 
            dataBoteriaState.user.claimIsRoot,
          localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA')
        )
      );
    }
  };

  return (
    <Container>
      {loader && 
      <Loader
        type="Oval"
        color="#005dff"
        height={40}
        width={100}
        timeout={90000} 
        className="loader"
      />}
      <Logo />

      <FormBox 
        title={t('selected_organization.title')}
        splitBar={true}
        description={t('selected_organization.description')}
        inputs={[]}
        links={[]}
        selectedCompany={true}
        setSelectCompany={setSelectCompany}
        handleChangeCompany={handleChangeCompany}
        companies={companies}
        valueCompany={selectCompany?.fantasyName || ''}
        selectedSector={true}
        organization={selectOrganization}
        valueOrganization={selectOrganization?.name || ''}
        handleChangeOrganization={handleChangeOrganization}
        buttons={[
          {
            text: t('buttons.toBack'),
            on: true,
            to: '/'
          },
          {
            text: t('buttons.enter'),
            on: false,
            function: handleSelectCompanyOrganization
          }
        ]}
      />

      <AlterLanguage />

      <PoweredBy />
    </Container>
  );
}

export default SelectedOrganization;