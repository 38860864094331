import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  svg {
    background: transparent;
  }
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 24px;
  margin-top: 70px;
`;

export const PhotoEditor = styled.div`
  display: flex;
  align-items: center;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 0 24px;

  img {
    width: 64px;
    height: 64px;
    left: 280px;
    top: 197px;
    background: #D6E4FF;
    border-radius: 50px;
    object-fit: cover;
  }
`;

export const ButtomEditPhoto = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  height: 40px;
  background: #F3F5F9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #254EDB;
  margin-left: 16px;
  cursor: pointer;
`;

export const LabelInput = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.02em;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
  margin: 16px 0 8px;
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 0 24px;
`;

export const InputEditor = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 300px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: 'var(--sub-title-logo-dark)';
  margin-left: 24px;
`;

export const TitleLanguage = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  color: ${props => props.modeColor === 'Light Mode' || props.modeColor === null ? 
    'var(--title-logo-light)' :
    'var(--title-logo-dark)'
  };
  background: ${
    props => props.modeColor === 'Light Mode' || props.modeColor === null ?
    'var(--box-light)' :
    'var(--box-dark)'
  };
  padding: 24px;
`;

export const BoxLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
  width: 201px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #979AA5;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #5A5D68;
  margin-left: 24px;

  svg {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
  }
`;

export const BoxSetLanguage = styled.div`
  border: 1px solid #DADCE3;
  /* box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08); */
  border-radius: 4px;
  margin-top: -10px;
  z-index: 10;
  width: 201px;
  margin-top: 8px;
  margin-left: 24px;
  margin-bottom: 24px;
`;

export const Language = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 199px;
  height: 36px;
  background: #FFFFFF;
  cursor: pointer;

  &:hover {
    background: #D6E4FF;
  }
`;

export const ButtomSaveUpdates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 88px;
  height: 40px;
  background: #254EDB;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 16px 24px 30px;
  cursor: pointer;
  margin-bottom: ${props => props.margin ? '' : '95px'};
`;