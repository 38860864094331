import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container } from './styles.js';

import Menu from '../../../components/Items/Menu';
import ContainerPage from '../../../components/Items/ContainerPage';
import CustomBotSettings from '../../../components/Boteria/CustomBotSettings';

import { getUser } from '../../../store/UserData/UserData.action';
import { settingsBotActions } from '../../../store/Boteria/SettingsBot/Settings.action';
import { listBotsActions } from '../../../store/Boteria/ListBot/ListBot.action';

import { toast } from '../../../components/Items/CustomToast';

import padraoPhotoBot from '../../../assets/padrao-photo-bot.png';

function BotSettings() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const userData = useSelector(state => state.userData);
  const settingsBot = useSelector(state => state.settingsBot);
  const listBots = useSelector(state => state.listBots);

  const [nameBot, setNameBot] = useState('');

  useEffect(() => {
    dispatch(getUser(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));

    if(userData.prefererLanguage){
      if(userData.prefererLanguage === 'English'){
        i18n.changeLanguage('en');
      }

      if(userData.prefererLanguage === 'Português (Brasil)'){
        i18n.changeLanguage('pt-br');
      }
    }
  }, [userData.data]);

  useEffect(() => {
    dispatch(settingsBotActions(
      window.location.href.split('/')[5], 
      localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));
  }, [settingsBot._id]);

  useEffect(() => {
    dispatch(listBotsActions(
      localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));

    if(!Array.isArray(listBots)){
      toast.error(
        t('toast.errors.oops'),
        t(`${listBots.title}`)
      );
    }

    listBots.map(bot => {
      if(bot._id === window.location.href.split('/')[5]){
        setNameBot(bot.title);
      };
    });
  }, [listBots[0]._id]);

  return (
    <Container>
      <Menu/>
      <ContainerPage
        img={padraoPhotoBot}
        title={nameBot}
        subtitle={t('code7_products.boteria.subtitle')}
        hr={true}
      >
        <CustomBotSettings settingsBot={settingsBot} />
      </ContainerPage>
    </Container>
  );
}

export default BotSettings;