import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { 
  Container, 
  BoxProduct,
  Title,
  Subtitle,
  ImageProduct,
  ButtomFooter
} from './styles';

import imgBoxProduto from '../../assets/imgBoxProduto.png';

function ListCode7Products() {

  const { t } = useTranslation();

  const modeColorState = useSelector(state => state.changeModeColor);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [url, setUrl] = useState('');

  useEffect(() => {setModeColor(modeColorState);}, [modeColorState]);

  useEffect(() => {
    const currentUrl = window.location.href.split('/');

    setUrl(currentUrl[3]);
  }, [url]);

  const redirectFunction = (url) => {
    if(url.includes('https')){
      window.open(url);
    } else {
      window.open(url);
    }
  }

  return (
    <Container modeColor={modeColor}>

      <BoxProduct 
        active={true}
      >
        <ImageProduct modeColor={modeColor} src={imgBoxProduto} alt="Icone produto" />
        <Title modeColor={modeColor}>{t('code7_products.boteria.title')}</Title>
        <Subtitle modeColor={modeColor}>{t('code7_products.boteria.subtitle')}</Subtitle>
        <ButtomFooter 
          onClick={() => redirectFunction('https://code7.com/faq/boteria')}
        >
          {t('code7_products.boteria.help_center')}
        </ButtomFooter>
      </BoxProduct>

      <BoxProduct active={false}>
        <ImageProduct modeColor={modeColor} src={imgBoxProduto} alt="Icone produto" />
        <Title modeColor={modeColor}>{t('code7_products.omni.title')}</Title>
        <Subtitle modeColor={modeColor}>{t('code7_products.omni.subtitle')}</Subtitle>
        <ButtomFooter 
          onClick={() => redirectFunction('https://code7.com/produtos/omni/')}
        >
          {t('code7_products.omni.meet')}
        </ButtomFooter>
      </BoxProduct>

      <BoxProduct active={false}>
        <ImageProduct modeColor={modeColor} src={imgBoxProduto} alt="Icone produto" />
        <Title modeColor={modeColor}>{t('code7_products.journey_builder.title')}</Title>
        <Subtitle modeColor={modeColor}>{t('code7_products.journey_builder.subtitle')}</Subtitle>
        <ButtomFooter 
          onClick={() => redirectFunction('https://linktr.ee/code7jb')} disabled
        >
          {t('code7_products.journey_builder.meet')}
        </ButtomFooter>
      </BoxProduct>

    </Container>
  );
}

export default ListCode7Products;