import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IoMdTrash } from 'react-icons/io'
import { FiPower } from 'react-icons/fi';
import { FaRobot } from 'react-icons/fa';

import { listBotsActions } from '../../../store/Boteria/ListBot/ListBot.action';
import { publishBotActions } from '../../../store/Nuvemshop/PublishBot/PublishBot.action';
import { updateUserAction } from '../../../store/UpdateUser/UpdateUser.action';
import { deleteBotAction } from '../../../store/Boteria/DeleteBot/DeleteBot.action';

import { 
  Container,
  BoxBot,
  HeaderBox,
  ButtonActiveBot,
  TitleBot,
  ButtonOption,
  ActiveOrDisabledBotBox,
  Item,
  WarningNoBots
} from './styles';

import { toast } from '../../../components/Items/CustomToast';

import iconDown from '../../../assets/iconDown.png';

function ListOfBots() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const listBots = useSelector(state => state.listBots);
  const modeColorState = useSelector(state => state.changeModeColor);
  const userData = useSelector(state => state.userData);
  const publishBotState = useSelector(state => state.publishBot);
  const deleteBotState = useSelector(state => state.deleteBot);

  const [modeColor, setModeColor] = useState(modeColorState);
  const [activeOrDisabledBotBoxState, setActiveOrDisabledBotBoxState] = useState(false);
  const [botIdModal, setBotIdModal] = useState(0);
  const [botPublish, setBotPublish] = useState(1);
  const [nuvemshopIntegration, setNuvemshopIntegration] = useState(false);

  const modalRef = useRef(null);

  useEffect(() => {setModeColor(modeColorState)}, [modeColorState]);

  useEffect(() => {
    if(publishBotState.id === undefined){
      if(publishBotState === 0){
        toast.error(
          t('toast.errors.oops'),
          t('errors.publish_bot')
        );
      } else {
        toast.success(
          t('toast.success.correct'),
          t('toast.success.publish_bot')
        );

        setBotPublish(localStorage.getItem('BOT_ID'));
        dispatch(updateUserAction(
          {botId: localStorage.getItem('BOT_ID')},
          localStorage.getItem('API_TOKEN_PARTNERS')
        ));
        setActiveOrDisabledBotBoxState(false);
      }
    }
  }, [publishBotState]);

  useEffect(() => {
    if(deleteBotState.id === undefined && deleteBotState.id !== 0){
      setBotPublish(0);
      setActiveOrDisabledBotBoxState(false);
      toast.success(
        t('toast.success.correct'),
        t('toast.success.delete_bot')
      );
    }
  }, [deleteBotState]);

  useEffect(() => {
    dispatch(listBotsActions(
      localStorage.getItem('API_TOKEN_PARTNERS_BOTERIA'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));

    if(!Array.isArray(listBots)){
      toast.error(
        t('toast.errors.oops'),
        t(`${listBots.title}`)
      );
    }

    if(localStorage.getItem('USER_NUVEMSHOP') !== null){
      setNuvemshopIntegration(true);

      setTimeout(() => {
        localStorage.removeItem('USER_NUVEMSHOP')
      }, 500);
    }

    if(userData.data !== 'vazio'){
      if(userData.userIdStore === undefined){
        userData.integrations.map(integration => {
          if(integration.name === 'nuvemshop'){
            setNuvemshopIntegration(true);
          }
        });
      } else {
        setNuvemshopIntegration(true);
      }
      setBotPublish(userData.botPublish);
    } else {
      if(userData.integrations !== undefined){
        userData.integrations.map(integration => {
          if(integration.name === 'nuvemshop'){
            setNuvemshopIntegration(true);
          }
        });
      }
    }

  }, [listBots[0]?._id]);

  const openPageOrModal = (event, botId) => {
    if(event.target.id === modalRef.current.id){
      window.location.href = `/${window.location.href.split('/')[3]}/config/${botId}`
    }

    if(event.target.id === botId){
      setActiveOrDisabledBotBoxState(!activeOrDisabledBotBoxState)
      setBotIdModal(botId);
    }
  }
  
  const publishBot = (botAcitve, botId) => {
    if(!botAcitve){
      toast.error(
        t('toast.errors.oops'),
        t('errors.bot_is_not_active')
      );
    }

    if(botPublish.length > 5 && botPublish !== botId){
      toast.error(
        t('toast.errors.oops'),
        t('errors.bot_existing_on_store')
      );
    }

    if(botPublish === 0){
      dispatch(publishBotActions(
        localStorage.getItem('EMAIL'),
        botId,
        localStorage.getItem('API_TOKEN_PARTNERS')
      ));
      localStorage.setItem('BOT_ID', botId);
      setBotPublish(botId);
    }
  }

  const deleteBot = (botId) => {
    dispatch(deleteBotAction(
      localStorage.getItem('EMAIL'),
      localStorage.getItem('API_TOKEN_PARTNERS')
    ));
  }

  return (
    <Container modeColor={modeColor}>
      {listBots.length === 0 ?
      <WarningNoBots modeColor={modeColor}>
        {t('code7_products.boteria.whitout_bot')}
      </WarningNoBots> : 
      listBots.map(bot => {
        return (
          <BoxBot
            id="box"
            key={bot._id}
            modeColor={modeColor}
            ref={modalRef}
            onClick={(event) => openPageOrModal(event, bot._id)}
          >
            <HeaderBox modeColor={modeColor}>
              <div>
                <FaRobot />
              </div>
              {nuvemshopIntegration && (
                <ButtonActiveBot 
                  modeColor={modeColor}
                  botActive={
                    bot._id === botPublish && 
                    nuvemshopIntegration ? 
                    true : 
                    false
                  }
                > 
                  { 
                    bot._id === botPublish && 
                    nuvemshopIntegration 
                    ? 
                    t('code7_products.boteria.bot_on') 
                    : 
                    t('code7_products.boteria.bot_off')
                  }
                </ButtonActiveBot>
              )}
            </HeaderBox>
            <TitleBot modeColor={modeColor}>{bot.title}</TitleBot>
            {nuvemshopIntegration && 
              <ButtonOption id={bot._id}>
                {t('code7_products.boteria.turn_button')}
                <img src={iconDown} alt="Icone down" />
              </ButtonOption>
            }
            {activeOrDisabledBotBoxState && botIdModal === bot._id ? 
            <ActiveOrDisabledBotBox>
              <Item
                onClick={() => publishBot(bot.isActive, bot._id)}
              >
                <FiPower />
                {t('code7_products.boteria.turn_button_option_on')}
              </Item>
              <Item
                onClick={() => deleteBot(bot._id)}
              >
                <IoMdTrash />
                {t('code7_products.boteria.turn_button_option_off')}
              </Item>
            </ActiveOrDisabledBotBox> : ''}
          </BoxBot>
        )
      })
    }
    </Container>
  );
}

export default ListOfBots;