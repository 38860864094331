import api from '../api';

export const allOrganizationsService = async (token) => {
	const allOrganizations = await api.get('partner/bot/organizations', {
    headers: {
      token: token
    }
  })
	.then(result => {
		return result.data
	})
	.catch(err => {
		return err;
	});

	return allOrganizations;
}