import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxPromise from 'redux-promise';

import LoginReducer from './Auth/Login/Login.reducer';
import UserReducer from './Auth/CreateUser/User.reducer';
import CountMessagesReducer from './Boteria/CountMessages/CountMessages.reducer';
import ExtractMessagesCountReducer from './Boteria/ExtractMessagesCount/ExtractMessagesCount.reducer';
import ListBotReducer from './Boteria/ListBot/ListBot.reducer';
import SettingsReducer from './Boteria/SettingsBot/Settings.reducer';
import UpdateUserBoteriaReducer from './Boteria/UpdateUserBoteria/UpdateUserBoteria.reducer';
import AmountMessagesReducer from './Boteria/AmountMessages/AmountMessages.reducer';
import ChangeModeColorReducer from './ChangeModeColor/ChangeModeColor.reducer';
import UserDataReducer from './UserData/UserData.reducer';
import BoteriaLoginReducer from './Auth/BoteriaLogin/BoteriaLogin.reducer';
import UpdateUserReducer from './UpdateUser/UpdateUser.reducer';
import ExtractMessagesReducer from './Boteria/ExtractMessages/ExtractMessages.reducer';
import GetReportsReducer from './Boteria/GetReports/GetReports.reducer';
import UpdateSettingsBotReducer from './Boteria/UpdateSettingsBot/UpdateSettingsBot.reducer';
import GetScriptNuvemshopReducer from './Nuvemshop/GetScriptNuvemshop/GetScript.reducer';
import PublishBotReducer from './Nuvemshop/PublishBot/PublishBot.reducer';
import DeleteBotReducer from './Boteria/DeleteBot/DeleteBot.reducer';
import AllCompanysReducer from './Boteria/AllCompanys/AllCompanys.reducer';
import AllOrganizationsReducer from './Boteria/AllOrganizations/AllOrganizations.reducer';
import UpdateOrgIdAndCompanyIdReducer from './Boteria/UpdateOrgIdAndCompanyId/UpdateOrgIdAndCompanyId.reducer';
import ChangeTitleBotReducer from './Boteria/ChangeSettingsBot/TitleBot/TitleBot.reducer';
import ChangeMainColorReducer from './Boteria/ChangeSettingsBot/MainColor/MainColor.reducer';
import ChangeSecondaryColorReducer from './Boteria/ChangeSettingsBot/SecondaryColor/SecondaryColor.reducer';
import ChangeSecondaryTextColorReducer from './Boteria/ChangeSettingsBot/SecondaryTextColor/SecondaryTextColor.reducer';
import ChangeMainTextColorReducer from './Boteria/ChangeSettingsBot/MainTextColor/MainTextColor.reducer';
import ChangeSizeBotIconReducer from './Boteria/ChangeSettingsBot/SizeBotIcon/SizeBotIcon.reducer';
import ChangeBotImageReducer from './Boteria/ChangeSettingsBot/BotImage/BotImage.reducer';
import CopyBotTemplateReducer from './Boteria/CopyBotTemplate/CopyBotTemplate.reducer';
import RecoveryPasswordReducer from './Auth/RecoveryPassword/RecoveryPassword.reducer';

const rootReducer = combineReducers({
	user: UserReducer,
	login: LoginReducer,
	countMessages: CountMessagesReducer,
	extractMessageaCount: ExtractMessagesCountReducer,
	listBots: ListBotReducer,
	settingsBot: SettingsReducer,
	updateUserBoteria: UpdateUserBoteriaReducer,
	amountMessages: AmountMessagesReducer,
	changeModeColor: ChangeModeColorReducer,
	userData: UserDataReducer,
	boteriaLogin: BoteriaLoginReducer,
	updateUser: UpdateUserReducer,
	extractMessages: ExtractMessagesReducer,
	getReports: GetReportsReducer,
	updateSettingsBot: UpdateSettingsBotReducer,
	getScript: GetScriptNuvemshopReducer,
	publishBot: PublishBotReducer,
	deleteBot: DeleteBotReducer,
	allCompanys: AllCompanysReducer,
	allOrganizations: AllOrganizationsReducer,
	updateOrgIdAndCompanyId: UpdateOrgIdAndCompanyIdReducer,
	titleBotReducer: ChangeTitleBotReducer,
	mainColorReducer: ChangeMainColorReducer,
	mainTextColorReducer: ChangeMainTextColorReducer,
	secondaryColorReducer: ChangeSecondaryColorReducer,
	secondaryTextColorReducer: ChangeSecondaryTextColorReducer,
	sizeBotIconReducer: ChangeSizeBotIconReducer,
	botImageReducer: ChangeBotImageReducer,
	copyBotTemplateReducer: CopyBotTemplateReducer,
	recoveryPasswordReducer: RecoveryPasswordReducer
});

const store = createStore(rootReducer, applyMiddleware(reduxPromise));

export default store;